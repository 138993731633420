import { registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule, HammerModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule, FuseWidgetModule } from '@fuse/components';
import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { NgxJsonLdModule } from '@ngx-lite/json-ld';
import { AppComponent } from 'app/app.component';
import { fuseConfig } from 'app/fuse-config';
import { LayoutModule } from 'app/layout/layout.module';
import { googleTagManagerId } from 'environments/environment';
import { IntercomModule } from 'ng-intercom';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { NgChartsModule } from 'ng2-charts';
import { MarkdownService } from 'ngx-markdown';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { environment } from '../environments/environment';
import { AppRoutes } from './app.routes';
import { LoginModule } from './main/authentication/login/login.module';
import { RegistrationModule } from './main/authentication/registration/registration.module';
import { BillingModule } from './main/billing/billing.module';
import { ChatModule } from './main/chat/chat/chat.module';
import { DashboardModule } from './main/dashboard/dashboard.module';
import { DraggableModule } from './main/draggable/draggable.module';
import { DroppableInputComponent } from './main/droppable-input/droppable-input.component';
import { FavoritesPageModule } from './main/favorites-page/favorites-page.module';
import { FilterNewModule } from './main/filter-new/filter-new.module';
import { FilterModule } from './main/filter/filter.module';
import { FtpModule } from './main/ftp/ftp.module';
import { AdminCategoriesModule } from './main/kiosk/admin-categories/admin-categories.module';
import { ExploreProductsModule } from './main/marketplace/explore-products/explore-products.module';
import { MySuppliersNewModule } from './main/marketplace/my-suppliers-new/my-suppliers-new.module';
import { RetailerCatalogsModule } from './main/marketplace/retailer-catalogs/retailer-catalogs.module';
import { MyRetailersModule } from './main/my-retailers/my-retailers.module';
import { NotificationModule } from './main/notification/notification.module';
import { ProductDetailsPageModule } from './main/product-details-page/product-details-page.module';
import { NewFieldMappingModule } from './main/retailer-export-task-wizard/new-field-mapping/new-field-mapping.module';
import { SetupGuideModule } from './main/setup-guide/setup-guide.module';
import { HistoryModule } from './main/tasks/history/history.module';
import { TasksModule } from './main/tasks/tasks.module';
import { AddfilesModule } from './main/taskwizard/addfiles/addfiles.module';
import { TaskwizardModule } from './main/taskwizard/taskwizard.module';
import { UsersModule } from './main/users/users.module';
import { ProgressBarHandlerInterceptor } from './service/rest/interceptor/progress-bar-handler-interceptor.service';
import { TokenInterceptor } from './service/rest/interceptor/token-interceptor';
import { UseridInterceptor } from './service/rest/interceptor/userid-interceptor';
import { RoutingMetatagService } from './service/routing-metatag/routing-metatag.service';
import { AuthenticationEffects } from './store/authentication/authentication.effects';
import { authenticationMetaReducer, authenticationReducer } from './store/authentication/authentication.reducer';
import { BootstrapEffects } from './store/bootstrap/bootstrap.effects';
import { bootstrapReducer } from './store/bootstrap/bootstrap.reducer';
import { categoriesReducer } from './store/category/category.reducer';
import { counterReducer } from './store/counter/counter.reducer';
import { CountriesLocationsEffects } from './store/countries-locations/countries-locations.effects';
import { countriesLocationsReducer } from './store/countries-locations/countries-locations.reducer';
import { CurrencyEffects } from './store/currency/currency.effects';
import { currencyMetaReducer, currencyReducer } from './store/currency/currency.reducer';
import { EcomEffects } from './store/ecom/ecom.effects';
import { ecomsReducer } from './store/ecom/ecom.reducer';
import { FavoriteProductsEffects } from './store/favorites/favorites.effects';
import { favoriteProductsReducer } from './store/favorites/favorites.reducer';
import { GettingStartedEffects } from './store/getting-started/getting-started.effects';
import { gettingStartedReducer } from './store/getting-started/getting-started.reducer';
import { LanguageEffects } from './store/language/language.effects';
import { languageReducer } from './store/language/language.reducer';
import { UserPreferencesEffects } from './store/preferences/preferences.effects';
import { preferencesReducer } from './store/preferences/preferences.reducer';
import { ProductSearchEffects } from './store/product-search/product-search.effects';
import { productSearchMetaReducer, productSearchReducer } from './store/product-search/product-search.reducer';
import { RCatalogsEffects } from './store/rcatalogs/rcatalogs.effects';
import { rcatalogsReducer } from './store/rcatalogs/rcatalogs.reducer';
import { RetailerToCatalogEffects } from './store/retailer-to-catalog/retailer-to-catalog.effects';
import { retailerToCatalogReducer } from './store/retailer-to-catalog/retailer-to-catalog.reducer';
import { SnippetEffects } from './store/snippet/snippet.effects';
import { snippetReducer } from './store/snippet/snippet.reducer';
import { SubscriptionEffects } from './store/subscription/subscription.effects';
import { subscriptionsReducer } from './store/subscription/subscription.reducer';
import { SynceeAppsEffects } from './store/syncee-apps/syncee-apps.effects';
import { synceeAppsReducer } from './store/syncee-apps/syncee-apps.reducer';
import { TasksEffects } from './store/tasks/tasks.effects';
import { tasksReducer } from './store/tasks/tasks.reducer';
import { UsageEffects } from './store/usage/usage.effects';
import { usageReducer } from './store/usage/usage.reducer';
import { UserEffects } from './store/user/user.effects';
import { usersReducer } from './store/user/user.reducer';
import { StorageTranslateModule } from './translation/storage-translate.module';
import { HammerConfig } from './utils/hammer-config';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular';
import { ChunkErrorHandler } from './utils/error-handler/chunk-error-handler';
import { ReviewTimerService } from './service/review-timer/review-timer.service';
import { SynceeReviewAutoProcessService } from './main/syncee-review/service/syncee-review-auto-process.service';
import { AlibabaProductService } from './main/alibaba-new/alibaba-product.service';
import { AlibabaProductListenerService } from './main/alibaba-new/alibaba-product-listener.service';

import(`/node_modules/@angular/common/locales/${navigator.language.split('-')[0]}.mjs`).then((locale) => {
  registerLocaleData(locale.default, navigator.language);
});

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutes,
    // Material moment date module
    MatMomentDateModule,
    // Material
    MatButtonModule,
    MatInputModule,
    MatDividerModule,
    MatStepperModule,
    MatSelectModule,
    MatDialogModule,
    MatChipsModule,
    MatIconModule,
    MatDatepickerModule,
    MatRadioModule,
    MatCardModule,
    MatTooltipModule,
    MatListModule,
    MatMenuModule,
    MatToolbarModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    NgxMatSelectSearchModule,
    MatTableModule,
    MatTabsModule,
    MatPaginatorModule,
    MatSortModule,
    // Fuse modules
    FuseModule.forRoot(fuseConfig),
    FuseProgressBarModule,
    FuseSharedModule,
    FuseSidebarModule,
    FuseThemeOptionsModule,
    FuseWidgetModule,
    // Charts modules
    NgChartsModule,
    // App modules
    LayoutModule,
    LoginModule,
    UsersModule,
    RegistrationModule,
    FtpModule,
    TasksModule,
    TaskwizardModule,
    AddfilesModule,
    DraggableModule,
    NotificationModule,
    RetailerCatalogsModule,
    HistoryModule,
    HammerModule,
    AdminCategoriesModule,
    FilterModule,
    FilterNewModule,
    NewFieldMappingModule,
    DashboardModule,
    ExploreProductsModule,
    FavoritesPageModule,
    SetupGuideModule,
    BillingModule,
    MySuppliersNewModule,
    IntercomModule.forRoot({
      appId: 'xosr8hjn',
      updateOnRouterChange: true,
    }),
    CKEditorModule,
    ChatModule,
    StorageTranslateModule,
    NgxJsonLdModule,
    MyRetailersModule,
    StoreModule.forRoot(
      {
        count: counterReducer,
        productSearch: productSearchReducer,
        categories: categoriesReducer,
        preferences: preferencesReducer,
        rcatalogs: rcatalogsReducer,
        authentication: authenticationReducer,
        ecoms: ecomsReducer,
        users: usersReducer,
        bootstrap: bootstrapReducer,
        subscriptions: subscriptionsReducer,
        currency: currencyReducer,
        synceeApps: synceeAppsReducer,
        favorites: favoriteProductsReducer,
        languages: languageReducer,
        snippet: snippetReducer,
        retailerToCatalog: retailerToCatalogReducer,
        gettingStarted: gettingStartedReducer,
        tasks: tasksReducer,
        countriesLocations: countriesLocationsReducer,
        usage: usageReducer,
      },
      { metaReducers: [authenticationMetaReducer, currencyMetaReducer, productSearchMetaReducer] }
    ),
    EffectsModule.forRoot([
      AuthenticationEffects,
      EcomEffects,
      BootstrapEffects,
      SubscriptionEffects,
      ProductSearchEffects,
      UserPreferencesEffects,
      RCatalogsEffects,
      SynceeAppsEffects,
      FavoriteProductsEffects,
      LanguageEffects,
      CurrencyEffects,
      SnippetEffects,
      TasksEffects,
      RetailerToCatalogEffects,
      GettingStartedEffects,
      UsageEffects,
      UserEffects, // effects implementing OnInitEffects should be last in order, bc effects are loaded synchronously as it seems
      CountriesLocationsEffects,
    ]),

    !environment.production ? StoreDevtoolsModule.instrument() : [],
    ProductDetailsPageModule,
    ServiceWorkerModule.register('./ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    LazyLoadImageModule,
    RecaptchaV3Module,
  ],
  providers: [
    AlibabaProductService,
    SynceeReviewAutoProcessService,
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    // { provide: HTTP_INTERCEPTORS, useClass: LoggingInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ProgressBarHandlerInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: UseridInterceptor, multi: true },
    {
      provide: LOCALE_ID,
      useValue: navigator.language,
    },
    { provide: 'googleTagManagerId', useValue: googleTagManagerId },
    // {provide: HTTP_INTERCEPTORS, useClass: AdminInterceptor, multi: true}
    { provide: HAMMER_GESTURE_CONFIG, useClass: HammerConfig },
    RoutingMetatagService,
    MarkdownService,
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.recaptchaSiteKey },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService, ReviewTimerService, AlibabaProductListenerService],
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: ChunkErrorHandler,
    },
  ],
  bootstrap: [AppComponent],
  exports: [DroppableInputComponent, AppComponent],
})
export class AppModule {}

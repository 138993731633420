import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ExploreProductsService } from '../../../service/marketplace/explore-products/explore-products.service';
import { ProductSearchService } from '../../../service/product-search/product-search.service';
import { EcomVO } from '../../../service/ecom/ecom.service';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { ProductExtenderService } from '../../../service/extender/product-extender.service';
import { SearchProductVO } from '../../../vo/search-product-vo';

@Component({
  selector: 'app-similar-products',
  templateUrl: './similar-products.component.html',
  styleUrls: ['./similar-products.component.scss'],
})
export class SimilarProductsComponent implements OnInit, OnDestroy {
  @Input() category: number;
  @Input() selectedEcom: EcomVO;
  @Input() generateMicrodata = false;
  @Input() loading: boolean;
  products: SearchProductVO[];
  private readonly _unsubscribeAll: Subject<void>;

  constructor(private productSearch: ProductSearchService, private productExtenderService: ProductExtenderService) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.fetchProducts().subscribe((value) => (this.products = value));
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  fetchProducts(): Observable<SearchProductVO[]> {
    return this.productSearch
      .searchRandomProducts(this.selectedEcom, { category: this.category, autoOrder: 'true' })
      .pipe(this.productExtenderService.extendProducts(this.category ?? 1));
  }
}

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.state';
import { EcomVO } from 'app/service/ecom/ecom.service';
import { ExploreProductsService } from 'app/service/marketplace/explore-products/explore-products.service';
import { IsProductUsedResponse } from 'app/service/marketplace/explore-products/is-product-used';
import { NavigationEventService } from 'app/service/navigation-events/navigation-event.service';
import { ProductSearchService } from 'app/service/product-search/product-search.service';
import { SearchSessionMapperService } from 'app/service/search-session/search-session-mapper.service';
import { SearchSessionService } from 'app/service/search-session/search-session.service';
import { getSelectedEcomByRole } from 'app/store/ecom/ecom.selector';
import { RolesEnum } from 'app/vo/roles/roles';
import { MarketplaceFilter, SearchProductType, SearchProductVO } from 'app/vo/search-product-vo';
import { catchError, filter, map, Observable, of, Subject, switchMap, takeUntil, tap } from 'rxjs';
import { omitNullOrUndefined } from '../../../../utils/operator/omit-null-or-undefined';
import { isEqual } from 'lodash';
import { ShippingPreferencesService } from '../../../../service/preference/shipping-preferences.service';
import { finalize } from 'rxjs/operators';
import { ProductExtenderService } from '../../../../service/extender/product-extender.service';

@Component({
  selector: 'app-recommended-products-slider',
  templateUrl: './recommended-products-slider.component.html',
  styleUrls: ['./recommended-products-slider.component.scss'],
})
export class RecommendedProductsSliderComponent implements OnInit, OnDestroy {
  @Input() ecomCurrency = 'USD';

  productList: SearchProductVO[];
  hasSessionData: boolean;
  isProductUsed: IsProductUsedResponse;
  isLoading: boolean;

  private _unsubscribeAll: Subject<void>;

  constructor(
    private exploreProductsService: ExploreProductsService,
    private productSearchService: ProductSearchService,
    private searchSessionMapperService: SearchSessionMapperService,
    private searchSessionService: SearchSessionService,
    private store: Store<AppState>,
    private navigationEventService: NavigationEventService,
    private shippingPreferencesService: ShippingPreferencesService,
    private productExtenderService: ProductExtenderService
  ) {
    this._unsubscribeAll = new Subject<void>();
  }

  ngOnInit(): void {
    this.getSessionData();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  private getSessionData(): void {
    this.shippingPreferencesService.hasPreferences$
      .pipe(
        omitNullOrUndefined(),
        filter((hasShippingPreferences: boolean) => isEqual(hasShippingPreferences, true)),
        switchMap(() => this.searchSessionService.searchData$),
        tap(() => (this.isLoading = true)),
        filter((data) => !!data && data.length > 0),
        tap((data) => {
          this.hasSessionData = data.some((x) => x.search_datas.length > 0);
        }),
        switchMap((sessionData) =>
          this.store.select(getSelectedEcomByRole(RolesEnum.RETAILER)).pipe(
            takeUntil(this._unsubscribeAll),
            switchMap((ecom) =>
              this.searchSessionMapperService.getMarketplaceFilter(sessionData).pipe(
                catchError(() => of(null)),
                switchMap((marketplaceFilter) => this.getProducts(ecom, marketplaceFilter)),
                tap((products) => (this.productList = products)),
                switchMap((products) => this.getUsedProducts(ecom, products)),
                tap((isProductUsed) => (this.isProductUsed = isProductUsed))
              )
            )
          )
        ),
        takeUntil(this.navigationEventService.onNavigationStart),
        finalize(() => (this.isLoading = false))
      )
      .subscribe((): void => {
        this.isLoading = false;
      });
  }

  private getProducts(ecom: EcomVO, filters: MarketplaceFilter): Observable<SearchProductVO[]> {
    return this.productSearchService
      .searchRandomProducts(ecom, { ...filters, autoOrder: 'true' }, { from: 0, size: 18 }, SearchProductType.FULL_TEXT)
      .pipe(
        this.productExtenderService.extendProducts(filters?.category ?? 1),
        takeUntil(this.navigationEventService.onNavigationStart)
      );
  }

  private getUsedProducts(ecom: EcomVO, productList: SearchProductVO[]): Observable<IsProductUsedResponse> {
    if (!!ecom && !!productList?.length) {
      return this.exploreProductsService
        .getIsProductUsed(
          ecom.id,
          productList?.map((product) => product.ID)
        )
        .pipe(takeUntil(this.navigationEventService.onNavigationStart));
    } else {
      return of([]);
    }
  }
}

import { UpperRowItemWithChildren } from './models/upper-row-item-with-children';

export const TOOLBAR_UPPER_ROW_CONFIG_LEFT: UpperRowItemWithChildren[] = [
  {
    type: 'external-link',
    title: 'UPPER_ROW.HELP_CENTER.TITLE',
    link: 'https://help.syncee.co',
  },
];

export const TOOLBAR_UPPER_ROW_CONFIG_RIGHT: UpperRowItemWithChildren[] = [
  {
    type: 'inner-link',
    title: 'UPPER_ROW.SELL_ON_SYNCEE.TITLE',
    link: '/suppliers',
  },
  {
    type: 'menu',
    title: 'UPPER_ROW.INTEGRATIONS.TITLE',
    children: [
      {
        type: 'inner-link',
        title: 'UPPER_ROW.INTEGRATIONS.CHILDREN.SHOPIFY',
        link: '/integration/shopify',
      },
      {
        type: 'external-link',
        title: 'UPPER_ROW.INTEGRATIONS.CHILDREN.WIX',
        link: 'https://syncee.com/page/wix-dropshipping/',
      },
      {
        type: 'external-link',
        title: 'UPPER_ROW.INTEGRATIONS.CHILDREN.WOOCOMMERCE',
        link: 'https://syncee.com/page/woocommerce-dropshipping/',
      },
      {
        type: 'external-link',
        title: 'UPPER_ROW.INTEGRATIONS.CHILDREN.SQUARESPACE',
        link: 'https://syncee.com/page/squarespace-dropshipping/',
      },
      {
        type: 'external-link',
        title: 'UPPER_ROW.INTEGRATIONS.CHILDREN.BIGCOMMERCE',
        link: 'https://syncee.com/page/bigcommerce-dropshipping/',
      },
      {
        type: 'external-link',
        title: 'UPPER_ROW.INTEGRATIONS.CHILDREN.ECWID',
        link: 'https://syncee.com/page/ecwid-dropshipping/',
      },
      {
        type: 'external-link',
        title: 'UPPER_ROW.INTEGRATIONS.CHILDREN.JUMPSELLER',
        link: 'https://syncee.com/page/jumpseller-dropshipping/',
      },
      {
        type: 'external-link',
        title: 'UPPER_ROW.INTEGRATIONS.CHILDREN.EKM',
        link: 'https://syncee.com/page/ekm-dropshipping/',
      },
      {
        type: 'external-link',
        title: 'UPPER_ROW.INTEGRATIONS.CHILDREN.SHOPRENTER',
        link: 'https://syncee.com/page/shoprenter-dropshipping/',
      },
      {
        type: 'external-link',
        title: 'UPPER_ROW.INTEGRATIONS.CHILDREN.KMO',
        link: 'https://syncee.com/page/kmo-shops-dropshipping/',
      },
      {
        type: 'inner-link',
        title: 'UPPER_ROW.INTEGRATIONS.CHILDREN.SHOPIFY_PLUS',
        link: '/integration/shopify-plus',
      },
      {
        type: 'external-link',
        title: 'UPPER_ROW.INTEGRATIONS.CHILDREN.SYNCEE_GPT',
        link: 'https://syncee.com/page/ai-dropshipping-with-syncee-gpt/ ',
      },
    ],
  },
  {
    type: 'inner-link',
    title: 'UPPER_ROW.PRICING.TITLE',
    link: '/pricing',
  },
  {
    type: 'menu',
    title: 'UPPER_ROW.AFFILIATES.TITLE',
    children: [
      {
        type: 'inner-link',
        title: 'UPPER_ROW.AFFILIATES.CHILDREN.SUPPLIER_REFERRALS',
        link: '/supplier-referral',
      },
      {
        type: 'inner-link',
        title: 'UPPER_ROW.AFFILIATES.CHILDREN.RETAILER_REFERRALS',
        link: '/retailer-referral',
      },
      {
        type: 'inner-link',
        title: 'UPPER_ROW.AFFILIATES.CHILDREN.AFFILIATES',
        link: '/affiliates',
      },
    ],
  },
  {
    type: 'menu',
    title: 'UPPER_ROW.RESOURCES.TITLE',
    children: [
      {
        type: 'external-link',
        title: 'UPPER_ROW.RESOURCES.CHILDREN.BLOG',
        link: 'https://syncee.com/blog/',
      },
      {
        type: 'inner-link',
        title: 'UPPER_ROW.RESOURCES.CHILDREN.FAQ',
        link: '/faq',
      },
      {
        type: 'external-link',
        title: 'UPPER_ROW.RESOURCES.CHILDREN.REVIEWS',
        link: 'https://apps.shopify.com/syncee-1/reviews',
      },
    ],
  },
];

export enum StatusBadgeColorsEnum {
  PAID = '#D6EBB7',
  PENDING = '#FCE7B4',
  UNSUCCESSFUL = '#FACDCD',
  PARTIALLY_PAID = '#FCE7B4',
  UNPAID = '#FACDCD',
  FULFILLED = '#D6EBB7',
  PARTIALLY_FULFILLED = '#FCE7B4',
  UNFULFILLED = '#FACDCD',
  AUTO_ORDER = '#D4E2FE',
  PARTIALLY_AUTO_ORDER = '#FCE7B4',
  NON_AUTO_ORDER = '#E1E1E1',
  PAID_TEXT = '#68AE00',
  PENDING_TEXT = '#F6B000',
  UNSUCCESSFUL_TEXT = '#E56565',
  PARTIALLY_PAID_TEXT = '#F6B000',
  UNPAID_TEXT = '#E56565',
  FULFILLED_TEXT = '#68AE00',
  PARTIALLY_FULFILLED_TEXT = '#F6B000',
  UNFULFILLED_TEXT = '#E56565',
  AUTO_ORDER_TEXT = '#286DF8',
  PARTIALLY_AUTO_ORDER_TEXT = '#F6B000',
  NON_AUTO_ORDER_TEXT = '#7D7D7D',
  NOT_SYNCED = '#FACDCD',
  NOT_SYNCED_TEXT = '#E56565',
  SYNCED_TEXT = '#68AE00',
  SYNCED = '#D6EBB7',
  PARTIALLY_SYNCED_TEXT = '#F6B000',
  PARTIALLY_SYNCED = '#FCE7B4',
  REFUND = '#E1E1E1',
  REFUND_TEXT = '#7D7D7D',
  PARTIALLY_REFUND = '#E1E1E1',
  PARTIALLY_REFUND_TEXT = '#7D7D7D',
  PLACED = '#D4E2FE',
  PLACED_TEXT = '#286DF8',
}

<div class="image-selector-container">
  <div
    class="navigation-button-wrapper left-arrow"
    [class.hidden]="prevDisabled || images.length < 2"
    (click)="handleNavigation('prev')"
  >
    <ng-content select="[leftButton]"></ng-content>
  </div>
  <div
    class="navigation-button-wrapper right-arrow"
    [class.hidden]="nextDisabled || images.length < 2"
    (click)="handleNavigation('next')"
  >
    <ng-content select="[rightButton]"></ng-content>
  </div>
  <div>
    <ngx-slick-carousel #slickModal="slick-carousel" [config]="config" (beforeChange)="handleImageChanged($event)">
      <div ngxSlickItem *ngFor="let image of images">
        <img
          defaultImage="assets/images/transparent-400x400.png"
          [lazyLoad]="image | productImageFullCdn"
          [errorImage]="image"
          [alt]="altForImages"
          productImageErrorHandler
          [checkedOriginal]="true"
          width="746"
          height="668"
        />
      </div>
    </ngx-slick-carousel>
  </div>
</div>

import { Injectable } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.state';
import { isAuthenticatedSelector } from 'app/store/authentication/authentication.selector';
import { tap, filter, switchMap, map, takeUntil, Subject } from 'rxjs';
import { AlibabaProductService } from './alibaba-product.service';

@Injectable({
  providedIn: 'root',
})
export class AlibabaProductListenerService {
  private _unsubscribeAll: Subject<void> = new Subject();

  constructor(
    private _alibabaProductService: AlibabaProductService,
    private store: Store<AppState>,
    private router: ActivatedRoute
  ) {
    this._initListener();
  }

  private _initListener(): void {
    this.store
      .select(isAuthenticatedSelector)
      .pipe(
        filter((isAuthenticated) => !isAuthenticated),
        switchMap(() => this.router.queryParams),
        filter(
          (params) =>
            !!params['requestUrl'] && params['requestUrl'].startsWith('/retailer/alibaba/products?product_id=')
        ),
        map((params) => {
          const sParams = new URLSearchParams(params['requestUrl'].split('?')[1]);
          return sParams.get('product_id');
        }),
        takeUntil(this._unsubscribeAll)
      )
      .subscribe((productId) => {
        this._alibabaProductService.storeProductIdTemporally(productId);
      });
  }
}

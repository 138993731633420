<div class="ai-chat-body-outer" fxLayout="column" fxLayoutGap="4px">
  <div
    class="py-20 ai-chat-body"
    fxFlex="1 0 0"
    #body
    [ngStyle]="
      !aiChatMessagesService.allMessage?.length &&
      !aiChatMessagesService.waiting && { display: 'flex', alignItems: 'center', justifyContent: 'center' }
    "
  >
    <ng-container *ngFor="let message of aiChatMessagesService.allMessage; let last = last">
      <app-ai-chat-message *ngIf="!message.hide" [message]="message" [isLast]="last"></app-ai-chat-message>
    </ng-container>
    <app-ai-chat-message-loading *ngIf="aiChatMessagesService.waiting"></app-ai-chat-message-loading>
    <app-ai-chat-limit-reached *ngIf="aiChatLimitationsService.limitReached | async"></app-ai-chat-limit-reached>
  </div>
  <app-ai-chat-message-search-term-suggestion
    *ngIf="!!searchTermSuggestion && searchTermSuggestion.length > 0"
    fxFlex="0 0 auto"
    class="suggestions"
    [searchTerms]="searchTermSuggestion"
  ></app-ai-chat-message-search-term-suggestion>
</div>

import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { AppState } from '../../../app.state';
import { AuthenticationManagerService } from '../../../main/authentication/authentication-manager.service';
import { AuthenticationDialogResponse } from '../../../main/authentication/helpers/authentication-dialog-response';
import { AuthDialogType } from '../../../main/authentication/helpers/authentication-dialog-type';
import { isAuthenticatedSelector } from '../../../store/authentication/authentication.selector';

@Component({
  selector: 'app-login-dialog-entry',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './login-dialog-entry.component.html',
  styleUrls: ['./login-dialog-entry.component.scss'],
})
export class LoginDialogEntryComponent implements OnInit, OnDestroy {
  private dialogType: AuthDialogType;
  private unsubscribeAll: Subject<void>;

  constructor(
    private authenticationManagerService: AuthenticationManagerService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private store: Store<AppState>
  ) {
    this.unsubscribeAll = new Subject<void>();
  }

  ngOnInit(): void {
    this.getQueryParams();
    this.init();
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  private getQueryParams(): void {
    this.activatedRoute.params
      .pipe(
        take(1),
        tap((value) => {
          value.type === 'retailer'
            ? (this.dialogType = AuthDialogType.RETAILER)
            : value.type === 'supplier'
            ? (this.dialogType = AuthDialogType.SUPPLIER)
            : (this.dialogType = undefined);
        })
      )
      .subscribe();
  }

  private init(): void {
    this.store
      .select(isAuthenticatedSelector)
      .pipe(take(1))
      .subscribe((authenticated) => {
        if (authenticated) {
          this.handleClosed(false);
        } else if (!this.dialogType) {
          this.authenticationManagerService.emitOpenLoginDialog(AuthDialogType.RETAILER, this.callback.bind(this));
        } else {
          this.authenticationManagerService.emitOpenLoginDialog(this.dialogType, this.callback.bind(this));
        }
      });
  }

  private callback(response: AuthenticationDialogResponse): void {
    this.handleClosed(response?.success);
  }

  private handleClosed(success: boolean): void {
    this.activatedRoute.queryParams.pipe(take(1)).subscribe((params) => {
      if (success && !!params?.requestUrl) {
        const splitedReqParams = params.requestUrl.split('?');
        let paramsObject = {};
        if (splitedReqParams.length > 1) {
          const sParams = new URLSearchParams(splitedReqParams[1]);
          paramsObject = Object.fromEntries(sParams.entries());
        }

        this.router.navigate([splitedReqParams[0]], {
          queryParams: paramsObject,
          state: { skipFuseConfigUpdate: false },
        });
      } else {
        this.router.navigate(['../'], { relativeTo: this.activatedRoute, state: { skipFuseConfigUpdate: true } });
      }
    });
  }
}

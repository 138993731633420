import { AfterViewInit, Component, OnDestroy, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AddfilesComponent } from './addfiles/addfiles.component';
import { TaskwizardUpdateService } from '../../service/taskwizard/taskwizard-update.service';
import { ConnectionStateService } from '../../service/taskwizard/connection-state.service';
import { StepperService } from '../../service/taskwizard/stepper.service';
import { MatStep, MatStepper } from '@angular/material/stepper';
import { StepWrapperComponent } from '../step-wrapper/step-wrapper.component';
import { LoadingScreenService } from '../../service/loading-screen/loading-screen.service';
import { Constants } from '../../utils/Constants';
import { TaskFilterService } from '../../service/taskwizard/task-filter.service';
import { Observable } from 'rxjs';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { tap } from 'rxjs/operators';
import { Utils } from 'app/utils/utils';
import { SourceType } from './supplier-task-pricing/enums/source-type.enum';

@Component({
  selector: 'app-taskwizard',
  templateUrl: './taskwizard.component.html',
  styleUrls: ['./taskwizard.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
  ],
})
export class TaskwizardComponent implements AfterViewInit, OnDestroy {
  @ViewChild('taskStepper', { static: true }) public stepper: MatStepper;
  @ViewChild('mappingStep', { static: true }) private mappingMatStep: MatStep;
  @ViewChild('filterStep', { static: true }) private filterMatStep: MatStep;
  @ViewChild('catalogEditorStep', { static: true }) private catalogEditorStep: MatStep;
  @ViewChild('pricingEditorStep', { static: true }) private pricingEditorStep: MatStep;
  @ViewChild('categoryMappingStep', { static: true }) private categoryMappingStep: MatStep;

  addFilesComponent: AddfilesComponent;
  taskId: number;
  taskName: string;
  sourceType: SourceType;
  comments: any;
  enableComponents = false;
  isUpdate: boolean;
  loadingActive = false;

  @ViewChildren(MatStep) set matSteps(steps: QueryList<MatStep>) {
    this.stepperService.setMatSteps(steps.toArray());
  }

  @ViewChildren(StepWrapperComponent) set stepWrappers(content: QueryList<StepWrapperComponent>) {
    if (!Utils.isNullOrUndefined(this.stepperService)) {
      this.stepperService.setStepWrappers(content.toArray());
    }
  }

  constructor(
    private route: ActivatedRoute,
    public stepperService: StepperService,
    private _loadingService: LoadingScreenService,
    private connStateService: ConnectionStateService,
    public twUpdateService: TaskwizardUpdateService,
    public taskFilterService: TaskFilterService
  ) {}

  ngAfterViewInit(): void {
    this.stepperService.setStepper(this.stepper);
    this.route.queryParams.subscribe((params) => {
      this.taskId = params.task_id;
      this.isUpdate = !Utils.isNullOrUndefined(params.isUpdate);
      if (!Utils.isNullOrUndefined(params.isUpdate)) {
        this.openToUpdate();
        this.stepperService.initStepperSteps(true);
      } else {
        this.taskName = Utils.isNullOrUndefined(params.task_name) ? '' : params.task_name;
        this.initToNew();
        this.stepperService.initStepperSteps(false);
      }
    });
  }

  initToNew(): void {
    this.twUpdateService.isUpdate = false;
    this.connStateService.currentState = null;
    this.enableComponents = true;
  }

  openToUpdate(): void {
    this.twUpdateService.init(this.taskId).subscribe(
      (res) => {
        this.taskName = res.task.name;
        this.comments = res.comments;
        this.sourceType = res.task.sourceType as SourceType;
        this.enableComponents = true;
      },
      (error) => console.log(error)
    );
  }

  wizardStateChanged(): void {
    this.openToUpdate();
    this.loadingActive = true;
  }

  setSourceType(sourceType: string): void {
    this.sourceType = sourceType as SourceType;
  }

  hasDefaultFilter(): boolean {
    return Constants.SHOP_SOURCE_CONNECTION_TYPES.includes(this.sourceType) && !this.isUpdate;
  }

  saveSupplierTask(taskId: number, filters: any, variantFilters?: any): Observable<any> {
    return this.taskFilterService
      .saveFilters(taskId, JSON.stringify(filters), JSON.stringify(variantFilters))
      .pipe(tap(() => this.openToUpdate()));
  }

  ngOnDestroy(): void {
    this.stepperService.reset();
  }

  get loadingService(): LoadingScreenService {
    return this._loadingService;
  }

  handleGoToSettingsStep(): void {
    console.log(this.stepper);
    this.stepperService.setCurrentStep(2);
    this.stepperService.setIndex();
  }
}

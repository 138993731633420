import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.state';
import { EcomVO } from 'app/service/ecom/ecom.service';
import { ExploreProductsService } from 'app/service/marketplace/explore-products/explore-products.service';
import { IsProductUsedResponse } from 'app/service/marketplace/explore-products/is-product-used';
import { NavigationEventService } from 'app/service/navigation-events/navigation-event.service';
import { ProductSearchService } from 'app/service/product-search/product-search.service';
import { firstLevelCategoriesSelector } from 'app/store/category/category.selector';
import { getSelectedEcomByRole } from 'app/store/ecom/ecom.selector';
import { typesIdsByNameSelector, userPreferencesSelector } from 'app/store/preferences/preferences.selector';
import { Preference } from 'app/vo/Preferences/preference';
import { RolesEnum } from 'app/vo/roles/roles';
import { SearchProductVO } from 'app/vo/search-product-vo';
import { isEqual, uniq } from 'lodash';
import { combineLatest, from, Observable, of, Subject, switchMap } from 'rxjs';
import { distinctUntilChanged, filter, map, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { omitNullOrUndefined } from '../../../../utils/operator/omit-null-or-undefined';
import { ShippingPreferencesService } from '../../../../service/preference/shipping-preferences.service';
import { ProductExtenderService } from '../../../../service/extender/product-extender.service';

@Component({
  selector: 'app-preferred-categories-slider',
  templateUrl: './preferred-categories-slider.component.html',
  styleUrls: ['./preferred-categories-slider.component.scss'],
})
export class PreferredCategoriesSliderComponent implements OnInit, OnDestroy {
  @Input() ecomCurrency = 'USD';
  isProductUsed: IsProductUsedResponse;
  productLists: ProductList[] = [];
  preferredCategories: number[];

  private _selectedEcom: EcomVO;
  private _unsubscribeAll: Subject<void>;

  constructor(
    private store: Store<AppState>,
    private exploreProductsService: ExploreProductsService,
    private productSearchService: ProductSearchService,
    private navigationEventService: NavigationEventService,
    private shippingPreferencesService: ShippingPreferencesService,
    private productExtenderService: ProductExtenderService
  ) {
    this._unsubscribeAll = new Subject<void>();
  }

  ngOnInit(): void {
    this.init();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  private getSelectedEcom$(): Observable<EcomVO> {
    return this.store.select(getSelectedEcomByRole(RolesEnum.RETAILER));
  }

  private getPreferencesType$(): Observable<number> {
    return this.store.select(typesIdsByNameSelector).pipe(
      filter((data) => !!data),
      map((data) => data.SYNCEE_CATEGORY)
    );
  }

  private getPreferencesFromType$(preferenceType: number): Observable<Preference[]> {
    return this.store.select(userPreferencesSelector).pipe(
      filter((preferences) => !!preferences),
      map((preferences) => preferences.filter((p) => p.preferenceTypeId === preferenceType))
    );
  }

  private mapPreferredCategories(categories: number[]): number[] {
    const preferredCategories: number[] = [];

    if (categories && categories.length !== 0) {
      for (const categoryId of categories) {
        preferredCategories.push(categoryId);
        if (preferredCategories.length >= 3) {
          break;
        }
      }
    }

    return uniq(preferredCategories);
  }

  private getCategoriesFromPreferences$(preferences: Preference[]): Observable<number[]> {
    const preferenceValues: number[] = preferences.map((preference) => parseInt(preference.preferenceValue, 10)) ?? [];

    return this.store.select(firstLevelCategoriesSelector).pipe(
      filter((data) => !!data && data.length > 0),
      map((data) => this.mapPreferredCategories(data.filter((c) => preferenceValues.includes(c.id)).map((c) => c.id)))
    );
  }

  private getProductsFromCategory(preferredCategory: number): Observable<ProductList> {
    return this.getProducts(preferredCategory).pipe(
      mergeMap((products) =>
        this.getUsedProducts(products.map((prod) => prod.ID)).pipe(
          map((isProductUsedResp) => ({
            categoryId: preferredCategory,
            productList: products,
            isProductUsed: isProductUsedResp,
          }))
        )
      )
    );
  }

  private getProducts(categoryId: number): Observable<SearchProductVO[]> {
    return this.productSearchService
      .searchRandomProducts(this._selectedEcom, { category: categoryId, autoOrder: 'true', from: 0, size: 18 })
      .pipe(
        this.productExtenderService.extendProducts(categoryId ?? 1),
        takeUntil(this.navigationEventService.onNavigationStart)
      );
  }

  private getUsedProducts(productIds: string[]): Observable<IsProductUsedResponse> {
    if (!!this._selectedEcom && !!productIds?.length) {
      return this.exploreProductsService
        .getIsProductUsed(this._selectedEcom.id, productIds)
        .pipe(takeUntil(this.navigationEventService.onNavigationStart));
    } else {
      return of([]);
    }
  }

  private getCategories$(): Observable<number[]> {
    return this.getPreferencesType$().pipe(
      filter((type) => !!type),
      mergeMap((preferenceType: number) =>
        this.getPreferencesFromType$(preferenceType).pipe(
          filter((preferences) => !!preferences && preferences.length > 0),
          mergeMap((preferences: Preference[]) =>
            this.getCategoriesFromPreferences$(preferences).pipe(
              filter((categories) => !!categories && categories.length > 0),
              tap((preferredCategories) => (this.preferredCategories = preferredCategories)),
              map((categories: number[]) => categories)
            )
          )
        )
      )
    );
  }

  private selectedEcomAndCategories$(): Observable<[EcomVO, number[]]> {
    return combineLatest([this.getSelectedEcom$(), this.getCategories$()]).pipe(
      filter(([ecom, categories]) => ecom !== undefined && !!categories && categories.length > 0),
      distinctUntilChanged((v1, v2) => isEqual(v1, v2)),
      tap(([selectedEcom]) => (this._selectedEcom = selectedEcom))
    );
  }

  private init(): void {
    this.shippingPreferencesService.hasPreferences$
      .pipe(
        omitNullOrUndefined(),
        filter((hasShippingPreferences: boolean) => isEqual(hasShippingPreferences, true)),
        switchMap(() => this.selectedEcomAndCategories$()),
        mergeMap(([_, categories]: [EcomVO, number[]]) => from(categories)),
        mergeMap((categoryId: number) => this.getProductsFromCategory(categoryId)),
        takeUntil(this.navigationEventService.onNavigationStart)
      )
      .subscribe((list: ProductList): void => {
        this.productLists.push(list);
      });
  }
}

export interface ProductList {
  categoryId: number;
  productList: SearchProductVO[];
  isProductUsed: IsProductUsedResponse;
}

<ng-container *ngIf="isAutomated && hasCartService.hasCart">
  <a (click)="openOrderAndCatalogInformation()" class="clickable-content" *ngIf="supplierOrderLimit">
    {{
      'PRODUCT_PAGE.WHOLESALE_MINIMUM_ORDER.MIN_ORDER'
        | translate
          : {
              amount:
                amount
                | currencyExchangeToSelected : supplierPaymentCurrency
                | async
                | currencyTransformToSelected : 0
                | async
            }
    }}
  </a>
</ng-container>

import { Injectable } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { AlibabaService } from 'app/service/alibaba/alibaba.service';
import { CookieService } from 'ngx-cookie-service';
import { defaultIfEmpty, filter, map, Observable, of, switchMap, take, takeUntil, tap } from 'rxjs';

@Injectable()
export class AlibabaProductService {
  constructor(
    private _activatedRoute: ActivatedRoute,
    private _cookieService: CookieService,
    private _productService: AlibabaService
  ) {}

  public addNewProductIfExist(): Observable<boolean> {
    return this._getQueryParams().pipe(
      switchMap((qParams) => {
        const productId = qParams['product_id'] || this._cookieService.get('alibabaCachedProductId');
        if (!productId) {
          return of(false);
        }
        return this._productService.addProducts([productId]).pipe(map(() => true));
      }),
      tap(() => {
        if (!!this._cookieService.get('alibabaCachedProductId')) {
          this._cookieService.delete('alibabaCachedProductId');
        }
      }),
      defaultIfEmpty(false)
    );
  }

  public storeProductIdTemporallyIfExist(): Observable<void> {
    return this._getQueryParams().pipe(
      map((qParams) => qParams['product_id']),
      map((productId) => {
        if (!!productId) {
          this._cookieService.set('alibabaCachedProductId', productId);
        }
      })
    );
  }

  public storeProductIdTemporally(productId: string): void {
    this._cookieService.set('alibabaCachedProductId', productId);
  }

  private _getQueryParams(): Observable<Params> {
    return this._activatedRoute.queryParams.pipe(take(1));
  }
}

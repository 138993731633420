import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ConnectedPosition, OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleChange, MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { select, Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AppState } from 'app/app.state';
import { BootstrapService } from 'app/service/bootstrap/bootstrap.service';
import { CountriesManagerService } from 'app/service/countries-manager/countries-manager.service';
import { EcomService } from 'app/service/ecom/ecom.service';
import { CategoryService } from 'app/service/product-search/category.service';
import { CustomDividerComponent } from 'app/shared/components/custom-divider/custom-divider.component';
import { languages } from 'app/utils/Languages';
import { TRANSLATION_LANGUAGES } from 'app/utils/TranslationLanguages';
import { CatalogFeedSettingsVO } from 'app/vo/catalog';
import { CatalogFormVO } from 'app/vo/catalog-form-vo';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { hasRoleSelector } from '../../../store/user/user.selector';
import { CategoryVo } from '../../../vo/category-vo';
import { RolesEnum } from '../../../vo/roles/roles';
import { ChipWithAutocompleteComponent } from '../chip-with-autocomplete/chip-with-autocomplete.component';
import { AlertMultiComponent } from '../alert-multi/alert-multi.component';

@Component({
  selector: 'app-task-settings',
  standalone: true,
  imports: [
    CommonModule,
    FlexLayoutModule,
    TranslateModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatChipsModule,
    MatIconModule,
    MatTooltipModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatAutocompleteModule,
    OverlayModule,
    CustomDividerComponent,
    forwardRef(() => ChipWithAutocompleteComponent),
    AlertMultiComponent,
  ],
  templateUrl: './task-settings.component.html',
  styleUrls: ['./task-settings.component.scss'],
})
export class TaskSettingsComponent implements OnInit, OnDestroy {
  @Input() showProfitMargin = false;

  catalogForm: UntypedFormGroup = new UntypedFormGroup({});
  tasks: any[] = [];
  tags: string[] = [];
  translationLanguages = TRANSLATION_LANGUAGES;
  categories: CategoryVo[];
  platformList: any[];
  selectedPlatforms: any[];
  languages = languages;
  warehouseList = this.countriesManagerService.getOnlyCountries();
  approveNeededShown = false;
  maxLength = 1000;
  discountExamples = [
    { discount: '15%', rpm: '18%' },
    { discount: '20%', rpm: '25%' },
    { discount: '25%', rpm: '34%' },
    { discount: '30%', rpm: '42%' },
    { discount: '35%', rpm: '54%' },
    { discount: '40%', rpm: '67%' },
    { discount: '45%', rpm: '82%' },
    { discount: '50%', rpm: '100%' },
  ];
  positions: ConnectedPosition[] = [
    {
      originX: 'end',
      originY: 'bottom',
      overlayX: 'start',
      overlayY: 'center',
      offsetX: 10,
    },
  ];
  isAdmin$: Observable<boolean>;

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  private currentLang: string;
  private _unsubscribeAll: Subject<void>;
  private _isOpen = false;

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private store: Store<AppState>,
    private bootstrapService: BootstrapService,
    private countriesManagerService: CountriesManagerService,
    private ecomService: EcomService,
    private categoryService: CategoryService,
    private translateService: TranslateService
  ) {
    this._unsubscribeAll = new Subject();
    this.currentLang = this.translateService.currentLang;
    this.isAdmin$ = this.store.select(hasRoleSelector(RolesEnum.ADMIN));
  }

  ngOnInit(): void {
    this.createCatalogForm();
    this.subscribeToBootstrapCategories();
    this.getEcomTypes();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  private getEcomTypes(): void {
    this.ecomService.getEcomType().subscribe((res) => {
      this.platformList = res;
    });
  }

  private createCatalogForm(): void {
    this.catalogForm = this._formBuilder.group({
      name: ['', Validators.required],
      isHidden: [false],
      privateCode: [null],
      visibility: [null],
      settings: this._formBuilder.group({
        possibleMargin: [null],
        language: ['', [Validators.required]],
        shipping_type: [''],
        approveNeeded: [false],
        approveDescription: [''],
        needResponse: [false],
        showPrices: [true],
        sendEmail: [''],
        warehouseLocation: [''],
        mainCategories: [null],
        feedSettings: this._formBuilder.group({
          backorder: [false],
          shipping: [true],
        }),
        translate_source: [null],
        translate_target: [null],
      }),
      platformExceptions: [null],
    });

    if (this.showProfitMargin) {
      this.catalogForm
        .get('settings.possibleMargin')
        .setValidators([Validators.min(0), Validators.max(1000), Validators.required]);
    } else {
      this.catalogForm.get('settings.possibleMargin').clearValidators();
    }
  }

  private getCategories(): void {
    this.store
      .pipe(
        select((state) => state.categories),
        select((state) => state.categories),
        take(1)
      )
      .subscribe((tree) => {
        this.categories = (tree?.children).map((category) => {
          return { ...category, name: this.getCategoryName(category) };
        });
      });
  }

  private subscribeToBootstrapCategories(): void {
    this.bootstrapService.categoriesStored.pipe(takeUntil(this._unsubscribeAll)).subscribe((isStored) => {
      if (isStored) {
        this.getCategories();
      }
    });
  }

  getCategoryName(category: CategoryVo): string {
    return this.categoryService.getNameForCategory(category, this.currentLang);
  }

  privateChanged(event: MatSlideToggleChange): void {
    if (event.checked) {
      this.catalogForm.get('privateCode').setValidators(Validators.required);
    } else {
      this.catalogForm.get('privateCode').clearValidators();
    }
    this.catalogForm.get('privateCode').updateValueAndValidity();
  }

  getCatalogFormValue(): CatalogFormVO {
    const catalogFormValue = this.catalogForm.value;
    catalogFormValue.platformExceptions = !!catalogFormValue.platformExceptions
      ? catalogFormValue.platformExceptions.map((platform) => platform.id)
      : [];

    return JSON.parse(JSON.stringify(catalogFormValue));
  }

  openLayout(): void {
    this._isOpen = true;
  }

  closeLayout(): void {
    this._isOpen = false;
  }

  get isCodePrivate(): boolean {
    return this.catalogForm.get('isHidden').value;
  }

  get isFormValid(): boolean {
    return this.catalogForm.valid;
  }

  get isOpen(): boolean {
    return this._isOpen;
  }

  get approveNeeded(): boolean {
    return this.catalogForm?.get('settings.approveNeeded')?.value;
  }

  get language(): boolean {
    return this.catalogForm?.get('settings.language')?.value;
  }
}

export class CatalogSettingsVo {
  platformExceptions: number[];
  feedSettings: CatalogFeedSettingsVO;
}

<div class="syncee-review-dialog-wrapper" fxLayout="column" fxLayoutGap="32px">
  <ng-container [ngSwitch]="currentPhase">
    <app-select-rating
      *ngSwitchCase="'rating'"
      [selectedRating]="selectedRating"
      (ratingChanged)="handleRatingChange($event)"
    >
    </app-select-rating>
    <app-select-platform
      *ngSwitchCase="'platform'"
      (platformClick)="handlePlatformSelect($event)"
    ></app-select-platform>
    <app-select-feedback
      *ngSwitchCase="'feedback'"
      [rating]="selectedRating"
      (feedbackChange)="handleFeedBackSelection($event)"
    ></app-select-feedback>
    <ng-container *ngSwitchCase="'done'" [ngTemplateOutlet]="doneTemplate"></ng-container>
  </ng-container>
  <ng-container *ngIf="currentPhase !== 'platform'">
    <button
      [disabled]="
        (currentPhase === 'rating' && !selectedRating) ||
        (currentPhase === 'feedback' && !selectedFeedback?.answersIdList.length && !selectedFeedback?.customAnswer) ||
        loading
      "
      mat-flat-button
      [class]="currentPhase === 'done' ? 'syncee-light-blue-button' : 'syncee-blue-button'"
      (click)="handleButtonClick()"
    >
      <ng-container *ngIf="currentPhase === 'rating'">{{
        'SYNCEE_REVIEW_DIALOG.BUTTON.NEXT' | translate
      }}</ng-container>
      <ng-container *ngIf="currentPhase === 'feedback'">{{
        'SYNCEE_REVIEW_DIALOG.BUTTON.SUBMIT' | translate
      }}</ng-container>
      <ng-container *ngIf="currentPhase === 'done'">{{ 'SYNCEE_REVIEW_DIALOG.BUTTON.DONE' | translate }}</ng-container>
    </button>
  </ng-container>
</div>

<ng-template #doneTemplate>
  <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="52px">
    <app-avatar type="icon" [noRipple]="true" [diameter]="80">
      <mat-icon class="s-40">check</mat-icon>
    </app-avatar>
    <h2 class="my-0 centered-text">{{ 'SYNCEE_REVIEW_DIALOG.THANKS_FOR_FEEDBACK' | translate }}</h2>
  </div>
</ng-template>

import { Injectable } from '@angular/core';
import { SearchProductVO } from '../../vo/search-product-vo';
import { omitNullOrUndefined } from '../../utils/operator/omit-null-or-undefined';
import { ProductSearchResponse, ProductSearchService } from '../product-search/product-search.service';
import { EcomVO } from '../ecom/ecom.service';
import { getSelectedEcomByRole } from '../../store/ecom/ecom.selector';
import { RolesEnum } from '../../vo/roles/roles';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.state';
import { sampleSize } from 'lodash';
import { BehaviorSubject, filter, map, Observable, of, switchMap, tap } from 'rxjs';
import { switchMapWith } from '../../utils/operator/switch-map-with';
import { take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ProductExtenderService {
  private products: BehaviorSubject<ProductExtension> = new BehaviorSubject<ProductExtension>({});

  constructor(private productSearchService: ProductSearchService, private store: Store<AppState>) {}

  extendProducts(categoryId: number): (source: Observable<ProductSearchResponse>) => Observable<SearchProductVO[]> {
    return (source: Observable<ProductSearchResponse>): Observable<SearchProductVO[]> =>
      source.pipe(
        map((resp) => resp?.result),
        switchMapWith((products) =>
          products?.length && products.length < EXTEND_PRODUCT_EXTEND_TO_SIZE
            ? this.getExtraProducts(categoryId, EXTEND_PRODUCT_EXTEND_TO_SIZE - products.length)
            : of([])
        ),
        map(([original, extend]) => original.concat(...extend)),
        take(1)
      );
  }

  private getExtraProducts(categoryId: number, size: number): Observable<SearchProductVO[]> {
    return this.products.pipe(
      tap((products) => !products[categoryId] && this.handleMissingCategory(categoryId)),
      map((products) => products[categoryId]),
      omitNullOrUndefined(),
      map((products) => sampleSize(products, size))
    );
  }

  private handleMissingCategory(categoryId: number): void {
    this.getSelectedEcom()
      .pipe(
        switchMap((ecom) =>
          this.productSearchService.searchRandomProducts(ecom, {
            category: categoryId,
            from: 0,
            size: EXTEND_PRODUCT_FETCH_SIZE,
          })
        ),
        take(1)
      )
      .subscribe((products) => this.handleProductResponse(categoryId, products?.result));
  }

  private handleProductResponse(categoryId: number, products: SearchProductVO[]): void {
    const target: ProductExtension = structuredClone(this.products.value);
    this.products.next(Object.assign(target, { [categoryId]: products }));
  }

  private getSelectedEcom(): Observable<EcomVO> {
    return this.store.select(getSelectedEcomByRole(RolesEnum.RETAILER)).pipe(filter((ecom) => ecom !== undefined));
  }
}

const EXTEND_PRODUCT_FETCH_SIZE = 40;
const EXTEND_PRODUCT_EXTEND_TO_SIZE = 12;

interface ProductExtension {
  [categoryId: number]: SearchProductVO[];
}

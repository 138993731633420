import { Component, Input, OnInit } from '@angular/core';
import { AIChatMessage } from '../../../model/messages/ai-chat-message';

@Component({
  selector: 'app-ai-chat-message',
  templateUrl: './ai-chat-message.component.html',
  styleUrls: ['./ai-chat-message.component.scss'],
})
export class AiChatMessageComponent implements OnInit {
  @Input() message: AIChatMessage;
  @Input() isLast: boolean;

  constructor() {}

  ngOnInit(): void {}
}

import { PlatformItem } from './platform-item';
import { EcomTypeEnum } from '../../vo/enums/ecom-type-enum';

const PLATFORM_CONFIG: PlatformItem[] = [
  {
    key: EcomTypeEnum.SHOPIFY,
    name: 'Shopify',
    active: true,
    links: {
      getLinkToOrder: (payload) =>
        `https://admin.shopify.com/store/${payload.domain.split('.')[0]}/orders/${payload.platformOrderId}`,
      getLinkToProduct: (payload) =>
        `https://admin.shopify.com/store/${payload.domain.replace('.myshopify.com', '')}/products/${payload.productId}`,
    },
    images: {
      logo: 'assets/images/platforms/icons/shopify_52x52.png',
      black_logo: 'assets/images/integrations/shopify.png',
    },
    options: {
      beta: false,
      hasDataFeed: true,
      hasSupplierApp: true,
      hasRetailerApp: true,
      hasFreeTrial: true,
      datafeedIntegration: {
        hasImport: true,
        hasExport: true,
        hasVU: true,
      },
    },
    reviewBadge: {
      image: 'assets/images/platforms/icons/shopify_52x52.png',
      redirectLink: 'https://apps.shopify.com/syncee-1#modal-show=ReviewListingModal',
    },
    install: {
      canInstall: true,
      title: 'GETTING_STARTED.ADD_YOUR_STORE.PLATFORMS.SHOPIFY.TITLE',
      description: 'GETTING_STARTED.ADD_YOUR_STORE.PLATFORMS.SHOPIFY.DESCRIPTION',
      descriptionLong: 'GETTING_STARTED.ADD_YOUR_STORE.PLATFORMS.SHOPIFY.DESCRIPTION_LONG',
      logoIconPath: 'assets/images/platforms/icons/shopify_52x52.png',
      url: 'http://shopify.pxf.io/sync',
      needDialogForInstall: false,
      learnMoreUrlRetailer: 'https://help.syncee.co/en/articles/1039720-how-to-install-syncee-to-your-shopify-store',
    },
    transparentIconSrc: 'assets/images/platforms/transparent-icons/shopify.png',
  },
  {
    key: EcomTypeEnum.WIX,
    name: 'Wix',
    active: true,
    links: {
      getLinkToOrder: (payload) => `https://manage.wix.com/dashboard/${payload.storeId}/store/orders`,
      getLinkToProduct: (payload) => `https://manage.wix.com/dashboard/${payload.storeId}/store/products`,
    },
    images: {
      logo: 'assets/images/platforms/icons/wix_52x52.png',
      black_logo: 'assets/images/integrations/wix.png',
    },
    options: {
      beta: false,
      hasDataFeed: false,
      hasSupplierApp: true,
      hasRetailerApp: true,
      hasFreeTrial: false,
    },
    reviewBadge: {
      image: 'assets/images/platforms/icons/wix_52x52.png',
      redirectLink: 'https://www.wix.com/app-market/syncee-global-dropshipping',
    },
    install: {
      canInstall: true,
      title: 'GETTING_STARTED.ADD_YOUR_STORE.PLATFORMS.WIX.TITLE',
      description: 'GETTING_STARTED.ADD_YOUR_STORE.PLATFORMS.WIX.DESCRIPTION',
      descriptionLong: 'GETTING_STARTED.ADD_YOUR_STORE.PLATFORMS.WIX.DESCRIPTION_LONG',
      logoIconPath: 'assets/images/platforms/icons/wix_52x52.png',
      url: 'https://www.wix.com/ecommerce/website',
      needDialogForInstall: false,
      learnMoreUrlRetailer: 'https://help.syncee.co/en/articles/4805013-how-to-install-syncee-to-your-wix-store',
    },
    transparentIconSrc: 'assets/images/platforms/transparent-icons/wix.png',
  },
  {
    key: EcomTypeEnum.WOOCOMMERCE,
    name: 'Woocommerce',
    active: true,
    links: {
      getLinkToOrder: (payload) =>
        `https://${payload.domain}/wp-admin/post.php?post=${payload.platformOrderId}&action=edit`,
      getLinkToProduct: (payload) =>
        `https://${payload.domain}/wp-admin/post.php?post=${payload.productId}&action=edit`,
    },
    images: {
      logo: 'assets/images/platforms/icons/woocommerce_52x52.png',
      black_logo: 'assets/images/integrations/woo.png',
    },
    options: {
      beta: false,
      hasDataFeed: false,
      hasSupplierApp: true,
      hasRetailerApp: true,
      hasFreeTrial: true,
    },
    reviewBadge: {
      img: 'assets/images/platforms/icons/woocommerce_52x52.png',
      redirectLink: 'https://woocommerce.com/products/syncee-global-dropshipping/',
    },
    install: {
      canInstall: true,
      title: 'GETTING_STARTED.ADD_YOUR_STORE.PLATFORMS.WOOCOMMERCE.TITLE',
      description: 'GETTING_STARTED.ADD_YOUR_STORE.PLATFORMS.WOOCOMMERCE.DESCRIPTION',
      descriptionLong: 'GETTING_STARTED.ADD_YOUR_STORE.PLATFORMS.WOOCOMMERCE.DESCRIPTION_LONG',
      logoIconPath: 'assets/images/platforms/icons/woocommerce_52x52.png',
      url: 'https://woocommerce.com/',
      needDialogForInstall: true,
      learnMoreUrlRetailer:
        'https://help.syncee.co/en/articles/5074038-how-to-install-syncee-to-your-wordpress-store-woocommerce-integration',
    },
    transparentIconSrc: 'assets/images/platforms/transparent-icons/woo.png',
  },
  {
    key: EcomTypeEnum.BIGCOMMERCE,
    name: 'BigCommerce',
    active: true,
    links: {
      getLinkToOrder: (payload) => `https://${payload.domain}/manage/orders/${payload.platformOrderId}`,
      getLinkToProduct: (payload) => `https://${payload.domain}/manage/products/edit/${payload.productId}`,
    },
    images: {
      logo: 'assets/images/platforms/icons/bigcommerce_52x52.png',
      black_logo: 'assets/images/integrations/bigcommerce.png',
    },
    options: {
      beta: false,
      hasDataFeed: false,
      hasSupplierApp: true,
      hasRetailerApp: true,
      hasFreeTrial: true,
    },
    reviewBadge: {
      image: 'assets/images/platforms/icons/bigcommerce_52x52.png',
      redirectLink: 'https://www.bigcommerce.com/apps/syncee-global-dropshipping/#marketplace-appDetail-reviews',
    },
    install: {
      canInstall: true,
      title: 'GETTING_STARTED.ADD_YOUR_STORE.PLATFORMS.BIGCOMMERCE.TITLE',
      description: 'GETTING_STARTED.ADD_YOUR_STORE.PLATFORMS.BIGCOMMERCE.DESCRIPTION',
      descriptionLong: 'GETTING_STARTED.ADD_YOUR_STORE.PLATFORMS.BIGCOMMERCE.DESCRIPTION_LONG',
      logoIconPath: 'assets/images/platforms/icons/bigcommerce_52x52.png',
      url: 'https://www.bigcommerce.com/dm/syncee/',
      needDialogForInstall: false,
      learnMoreUrlRetailer:
        'https://help.syncee.co/en/articles/4707116-how-to-install-syncee-to-your-bigcommerce-store',
    },
    transparentIconSrc: 'assets/images/platforms/transparent-icons/bigcommerce.png',
  },
  {
    key: EcomTypeEnum.ECWID,
    name: 'Ecwid',
    active: true,
    links: {
      getLinkToOrder: (payload) => `https://my.ecwid.com/store/${payload.storeId}#order:id=${payload.platformOrderId}`,
      getLinkToProduct: (payload) =>
        `https://my.ecwid.com/store/${payload.storeId}#product:mode=edit&id=${payload.productId}`,
    },
    images: {
      logo: 'assets/images/platforms/icons/ecwid_52x52.png',
      black_logo: 'assets/images/integrations/ecwid-by-lightspeed.png',
    },
    options: {
      beta: false,
      hasDataFeed: false,
      hasSupplierApp: true,
      hasRetailerApp: true,
      hasFreeTrial: true,
    },
    reviewBadge: {
      image: 'assets/images/platforms/icons/ecwid_52x52.png',
      redirectLink: 'https://www.ecwid.com/apps/featured/syncee',
    },
    install: {
      canInstall: true,
      title: 'GETTING_STARTED.ADD_YOUR_STORE.PLATFORMS.ECWID.TITLE',
      description: 'GETTING_STARTED.ADD_YOUR_STORE.PLATFORMS.ECWID.DESCRIPTION',
      descriptionLong: 'GETTING_STARTED.ADD_YOUR_STORE.PLATFORMS.ECWID.DESCRIPTION_LONG',
      logoIconPath: 'assets/images/platforms/icons/ecwid_52x52.png',
      url: 'http://open.ecwid.com/66MDMB',
      needDialogForInstall: false,
      learnMoreUrlRetailer:
        'https://help.syncee.co/en/articles/4713806-how-to-install-syncee-to-your-ecwid-by-lightspeed-store',
    },
    transparentIconSrc: 'assets/images/platforms/transparent-icons/ecwid-by-lightspeed.png',
  },
  {
    key: EcomTypeEnum.KMOSHOPS,
    name: 'Kmoshops',
    active: true,
    links: {
      getLinkToOrder: () => ``, // TODO: MISSING
      getLinkToProduct: () => ``, // TODO: MISSING
    },
    images: {
      logo: 'assets/images/platforms/icons/kmo_52x52.png',
    },
    options: {
      beta: false,
      hasDataFeed: true,
      hasSupplierApp: false,
      hasRetailerApp: true,
      hasFreeTrial: true,
      datafeedIntegration: {
        hasImport: true,
        hasExport: false,
        hasVU: false,
      },
    },
    install: {
      canInstall: true,
      title: 'GETTING_STARTED.ADD_YOUR_STORE.PLATFORMS.KMO_SHOPS.TITLE',
      description: 'GETTING_STARTED.ADD_YOUR_STORE.PLATFORMS.KMO_SHOPS.DESCRIPTION',
      descriptionLong: 'GETTING_STARTED.ADD_YOUR_STORE.PLATFORMS.KMO_SHOPS.DESCRIPTION_LONG',
      logoIconPath: 'assets/images/platforms/icons/kmo_52x52.png',
      type: EcomTypeEnum.KMOSHOPS,
      needDialogForInstall: false,
    },
    transparentIconSrc: 'assets/images/platforms/transparent-icons/kmo-shops.png',
  },
  {
    key: EcomTypeEnum.JUMPSELLER,
    name: 'Jumpseller',
    active: true,
    links: {
      getLinkToOrder: (payload) => `https://${payload.domain}/admin/en/orders/edit/${payload.platformOrderId}`,
      getLinkToProduct: (payload) => `https://${payload.domain}/admin/en/products/edit/${payload.productId}`,
    },
    images: {
      logo: 'assets/images/platforms/icons/jumpseller_52x52.png',
      black_logo: 'assets/images/integrations/jumpseller.png',
    },
    options: {
      beta: false,
      hasDataFeed: true,
      hasSupplierApp: true,
      hasRetailerApp: true,
      hasFreeTrial: true,
      datafeedIntegration: {
        hasImport: true,
        hasExport: false,
        hasVU: false,
      },
    },
    install: {
      canInstall: true,
      title: 'GETTING_STARTED.ADD_YOUR_STORE.PLATFORMS.JUMPSELLER.TITLE',
      description: 'GETTING_STARTED.ADD_YOUR_STORE.PLATFORMS.JUMPSELLER.DESCRIPTION',
      descriptionLong: 'GETTING_STARTED.ADD_YOUR_STORE.PLATFORMS.JUMPSELLER.DESCRIPTION_LONG',
      logoIconPath: 'assets/images/platforms/icons/jumpseller_52x52.png',
      url: 'https://jumpseller.com?ref=syncee-affiliate',
      needDialogForInstall: true,
      learnMoreUrlRetailer: 'https://help.syncee.co/en/articles/3804278-how-to-connect-jumpseller-as-a-retailer',
    },
    transparentIconSrc: 'assets/images/platforms/transparent-icons/jumpseller.png',
  },
  {
    key: EcomTypeEnum.SHOPRENTER,
    name: 'Shoprenter',
    active: true,
    links: {
      getLinkToOrder: (payload) =>
        `https://${payload.domain}/admin/index.php?route=sale/order/update&order_id=${payload.platformOrderId}`,
      getLinkToProduct: (payload) =>
        `https://${payload.domain}/admin/index.php?route=catalog/product/update&product_id=${payload.productId}`,
    },
    images: {
      logo: 'assets/images/platforms/icons/shoprenter_52x52.png',
      black_logo: 'assets/images/integrations/shoprenter.png',
    },
    options: {
      beta: false,
      hasDataFeed: true,
      hasSupplierApp: true,
      hasRetailerApp: true,
      hasFreeTrial: true,
      datafeedIntegration: {
        hasImport: true,
        hasExport: false,
        hasVU: false,
      },
    },
    install: {
      canInstall: true,
      title: 'GETTING_STARTED.ADD_YOUR_STORE.PLATFORMS.SHOPRENTER.TITLE',
      description: 'GETTING_STARTED.ADD_YOUR_STORE.PLATFORMS.SHOPRENTER.DESCRIPTION',
      descriptionLong: 'GETTING_STARTED.ADD_YOUR_STORE.PLATFORMS.SHOPRENTER.DESCRIPTION_LONG',
      logoIconPath: 'assets/images/platforms/icons/shoprenter_52x52.png',
      url: 'https://www.shoprenter.hu/',
      needDialogForInstall: true,
      learnMoreUrlRetailer:
        'https://help.syncee.co/hu/articles/4798934-hogyan-kapcsolhato-ossze-a-syncee-a-shoprenter-webaruhazammal',
    },
    transparentIconSrc: 'assets/images/platforms/transparent-icons/shoprenter.png',
  },
  {
    key: EcomTypeEnum.SQUARESPACE,
    name: 'Squarespace',
    active: true,
    links: {
      getLinkToOrder: (payload) => `https://${payload.domain}/config/commerce/orders`,
      getLinkToProduct: (payload) => `https://${payload.domain}/config/commerce/inventory`,
    },
    images: {
      logo: 'assets/images/platforms/icons/squarespace_52x52.png',
      black_logo: 'assets/images/integrations/squarespace.png',
    },
    options: {
      beta: false,
      hasDataFeed: false,
      hasSupplierApp: true,
      hasRetailerApp: true,
      hasFreeTrial: true,
    },
    install: {
      canInstall: true,
      title: 'GETTING_STARTED.ADD_YOUR_STORE.PLATFORMS.SQUARESPACE.TITLE',
      description: 'GETTING_STARTED.ADD_YOUR_STORE.PLATFORMS.SQUARESPACE.DESCRIPTION',
      descriptionLong: 'GETTING_STARTED.ADD_YOUR_STORE.PLATFORMS.SQUARESPACE.DESCRIPTION_LONG',
      logoIconPath: 'assets/images/platforms/icons/squarespace_52x52.png',
      url: 'https://www.squarespace.com/',
      needDialogForInstall: false,
      learnMoreUrlRetailer:
        'https://help.syncee.co/en/articles/4805021-how-to-install-syncee-to-your-squarespace-store',
    },
    transparentIconSrc: 'assets/images/platforms/transparent-icons/squarespace.png',
  },
  {
    key: EcomTypeEnum.UNSAS,
    name: 'Unas',
    active: true,
    links: {
      getLinkToOrder: () => `https://shop.unas.hu/admin_order.php`,
      getLinkToProduct: () => ``, // TODO: MISSING
    },
    images: {
      logo: 'assets/images/platforms/icons/unas_52x52.png',
      black_logo: 'assets/images/integrations/unas.png',
    },
    options: {
      beta: false,
      hasDataFeed: true,
      hasSupplierApp: true,
      hasRetailerApp: false,
      hasFreeTrial: true,
    },
    install: {
      canInstall: true,
      title: 'GETTING_STARTED.ADD_YOUR_STORE.PLATFORMS.UNAS.TITLE',
      description: 'GETTING_STARTED.ADD_YOUR_STORE.PLATFORMS.UNAS.DESCRIPTION',
      descriptionLong: 'GETTING_STARTED.ADD_YOUR_STORE.PLATFORMS.UNAS.DESCRIPTION_LONG',
      logoIconPath: 'assets/images/platforms/icons/unas_52x52.png',
      url: 'https://unas.hu/',
      needDialogForInstall: false,
    },
    transparentIconSrc: 'assets/images/platforms/transparent-icons/unas.png',
  },
  {
    key: EcomTypeEnum.EKM,
    name: 'EKM',
    active: true,
    links: {
      getLinkToOrder: () => ``, // TODO: MISSING
      getLinkToProduct: () => ``, // TODO: MISSING
    },
    images: {
      logo: 'assets/images/platforms/icons/ekm_52x52.png',
    },
    options: {
      beta: true,
      hasDataFeed: false,
      hasSupplierApp: false,
      hasRetailerApp: true,
      hasFreeTrial: true,
    },
    reviewBadge: {
      image: 'assets/images/platforms/icons/ekm_52x52.png',
      redirectLink: 'https://www.ekmpartners.com/syncee---global-dropshipping-79884-p.asp',
    },
    install: {
      title: 'GETTING_STARTED.ADD_YOUR_STORE.PLATFORMS.EKM.TITLE',
      description: 'GETTING_STARTED.ADD_YOUR_STORE.PLATFORMS.EKM.DESCRIPTION',
      descriptionLong: 'GETTING_STARTED.ADD_YOUR_STORE.PLATFORMS.EKM.DESCRIPTION_LONG',
      logoIconPath: 'assets/images/platforms/icons/ekm_52x52.png',
      url: 'https://www.ekm.com/offers/partner-signup.asp?tap_a=17618-24221b&tap_s=2538854-1691a0',
      needDialogForInstall: false,
      learnMoreUrlRetailer: 'https://help.syncee.co/en/articles/6079001-how-to-install-syncee-to-your-ekm-store',
    },
    transparentIconSrc: 'assets/images/platforms/transparent-icons/ekm.png',
  },
  // {
  //   key: EcomTypeEnum.SHOPLAZZA,
  //   name: 'Shoplazza',
  //   active: true,
  //   links: {
  //     getLinkToOrder: (payload) => `https://${payload.domain}/admin/smart_apps/base/order/_dealing`,
  //     getLinkToProduct: (payload) => `https://${payload.domain}/admin/smart_apps/python/products`,
  //   },
  //   images: {
  //     logo: 'assets/images/logos/shoplazza.png',
  //   },
  //   options: {
  //     beta: true,
  //     hasDataFeed: false,
  //     hasSupplierApp: false,
  //     hasRetailerApp: true,
  //     hasFreeTrial: true,
  //   },
  //   reviewBadge: {
  //     image: 'assets/images/platforms/icons/shopplaza_52x52.png',
  //     redirectLink: 'https://appstore.shoplazza.com/detail/47265/',
  //   },
  // },
] as PlatformItem[];

export const PLATFORM = PLATFORM_CONFIG.filter((platform) => platform.active);

export const getPlatformByType = (type: EcomTypeEnum) => PLATFORM.find((platform) => platform.key === type);

import { Injectable } from '@angular/core';
import { AIChatMessage, AIChatMessageSource, AIChatMessageType } from '../model/messages/ai-chat-message';
import { AiChatDto } from '../model/dto/ai-chat-dto';
import { AiChatFunctionCallName } from '../model/dto/ai-chat-function-call';
import { MarketplaceFilter } from '../../../vo/search-product-vo';
import { AiChatFilter } from '../model/dto/ai-chat-filter';
import { CountryCodeNameMapperService } from '../../../service/country-code-name-mapper.service';
import { AiChatRequestMessage, AiChatRole } from '../model/dto/ai-chat-request-message';

@Injectable({
  providedIn: 'root',
})
export class AiChatRestMapperService {
  constructor(private countryCodeNameMapperService: CountryCodeNameMapperService) {}

  mapMessagesToRequest(messages: AIChatMessage[]): AiChatRequestMessage[] {
    return messages.map((message) => ({
      role: this.mapSourceToRole(message.source),
      parts: message.raw,
    }));
  }

  mapDtoToMessage(
    rawDto: AiChatDto[],
    dto: AiChatDto,
    first: boolean,
    hasMultipleResponse: boolean,
    forceType?: AIChatMessageType
  ): AIChatMessage {
    const type = forceType ?? this.getMessageType(dto);
    const allText = rawDto.every((raw) => !raw.functionCall && !!raw.text);
    const hide =
      (hasMultipleResponse && !first && allText) ||
      (type === AIChatMessageType.SIMPLE_CHAT && !dto.text?.trim?.().length);
    return {
      raw: rawDto,
      type,
      needToRequest: hasMultipleResponse ? first : true,
      source: AIChatMessageSource.RESPONSE,
      hide,
      payload: {
        ...(type === AIChatMessageType.FILTER && { filter: this.getFilter(dto) }),
        ...(type === AIChatMessageType.SIMPLE_CHAT && {
          simpleMessage: hide ? dto.text : rawDto.map((raw) => raw.text).join(''),
        }),
        ...(type === AIChatMessageType.SEARCH_TERM_SUGGESTION && { searchTerms: dto.functionCall.args.searchTerms }),
      },
    };
  }

  mapSourceToRole(source: AIChatMessageSource): AiChatRole {
    switch (source) {
      case AIChatMessageSource.COMMAND:
        return AiChatRole.USER;
      case AIChatMessageSource.RESPONSE:
        return AiChatRole.MODEL;
    }
  }

  mapFunctionCallToType(functionCallName: AiChatFunctionCallName): AIChatMessageType {
    switch (functionCallName) {
      case AiChatFunctionCallName.SEARCH_PRODUCTS:
        return AIChatMessageType.FILTER;
      case AiChatFunctionCallName.SUGGEST_SEARCH_TERMS:
        return AIChatMessageType.SEARCH_TERM_SUGGESTION;
    }
  }

  private getFilter(dto: AiChatDto): MarketplaceFilter {
    const filter = dto.functionCall.args as AiChatFilter;
    return {
      ...(filter?.searchTerm && { search: filter.searchTerm }),
      ...(filter?.countryCode && {
        shipsFrom: this.countryCodeNameMapperService.mapCountryCodeToName(filter?.countryCode.toUpperCase()),
      }),
      // lang: filter?.productLanguageCode,
      ...(filter?.minPrice && { minPrice: filter.minPrice }),
      ...(filter?.maxPrice && { maxPrice: filter.maxPrice }),
      ...(filter?.premium && { premium: filter.premium ? 'true' : 'false' }),
      ...(filter?.approvalNeeded && { approveType: filter.approvalNeeded ? 'true' : 'false' }),
      // shippingType:
      category: 1,
    };
  }

  private getMessageType(dto: AiChatDto): AIChatMessageType {
    if (!!dto.text) {
      return AIChatMessageType.SIMPLE_CHAT;
    }
    switch (dto.functionCall.name) {
      case AiChatFunctionCallName.SEARCH_PRODUCTS:
        return AIChatMessageType.FILTER;
      case AiChatFunctionCallName.SUGGEST_SEARCH_TERMS:
        return AIChatMessageType.SEARCH_TERM_SUGGESTION;
    }
  }
}

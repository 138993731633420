/* tslint:disable:max-line-length */
import {
  Component,
  ComponentFactoryResolver,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Type,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { TranslateService } from '@ngx-translate/core';
import { ScrollService } from 'app/service/scroll/scroll.service';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { switchMap, take, takeUntil } from 'rxjs/operators';
import { TaskWatcherService } from '../../../../service/task/task-watcher.service';
import { FileDownloadService } from '../../../../service/taskwizard/file-download.service';
import { FileSettingsService } from '../../../../service/taskwizard/file-settings.service';
import { TaskFile } from '../../../../service/taskwizard/taskwizard-update-response';
import { TaskwizardUpdateService } from '../../../../service/taskwizard/taskwizard-update.service';
import { TaskwizardService } from '../../../../service/taskwizard/taskwizard.service';
import { DeleteModalVO } from '../../../../shared/components/dialogs/delete-modal/delete-modal-vo';
import { DeleteModalComponent } from '../../../../shared/components/dialogs/delete-modal/delete-modal.component';
import { Constants } from '../../../../utils/Constants';
import { Utils } from '../../../../utils/utils';
import { RolesEnum } from '../../../../vo/roles/roles';
import { ScheduleComponent } from '../../schedule/schedule.component';
import { StoreConnectedComponent, StoreConnectedComponentInput } from '../../store-connected/store-connected.component';
import { ConnectStoreComponent, ConnectStoreComponentInput } from '../connect-store/connect-store.component';
import { CsvSettingsComponent } from '../file-settings/csv-settings.component';
import { ExcelSettingsComponent } from '../file-settings/excel-settings.component';
import { ProductnodetreeComponent } from '../file-settings/productnodetree.component';
import { SettingsInterface } from '../file-settings/settings-interface';
import { FileUploadComponent } from '../file-upload/file-upload.component';
import { FtpclientComponent } from '../ftp/ftpclient.component';
import { PreviewDownloadedFileComponent } from '../ftp/preview-downloaded-file.component';
import { ChooseSettingsModalComponent } from '../modals/choose-settings-modal.component';
import { TaskWizardSampleService } from '../services/task-wizard-sample.service';
import { EcomInterface } from '../shopify/ecom-interface';
import { UrlSharedComponent } from '../url-upload/url-shared.component';
import { UrlUploadComponent } from '../url-upload/url-upload.component';
import { CsvSettingsInputVo } from '../vo/csv-settings-input-vo';
import { ExcelSettingsInputVo } from '../vo/excel-settings-input-vo';
import { FileUploadDownloadInputVo } from '../vo/file-upload-download-input-vo';
import { FileUploadResponseVo } from '../vo/file-upload-response-vo';
import { FtpDialogVO } from '../vo/ftp-dialog-vo';
import { FtpUploadResponseVO } from '../vo/ftp-upload-response-vo';
import { ProductNodeTreeVO } from '../vo/product-node-tree-vo';
import { SharedUrlUploadInputVo } from '../vo/shared-url-upload-input-vo';
import { TabFileDelTaskfileOutVo } from '../vo/tab-file-del-taskfile-out-vo';
import { TabFileManagerInputVo } from '../vo/tab-file-manager-input-vo';
import { TabFileManagerOutputVo } from '../vo/tab-file-manager-output-vo';
import { TabFileSettsavedOutVo } from '../vo/tab-file-settsaved-out-vo';

@Component({
  selector: 'app-filemanagertab',
  templateUrl: './filemanagertab.component.html',
  styleUrls: ['./filemanagertab.component.scss'],
})
export class FilemanagertabComponent implements OnInit, OnDestroy {
  private _inputSync: BehaviorSubject<TabFileManagerInputVo> = new BehaviorSubject<TabFileManagerInputVo>(null);

  @Input() set tabManagerInputs(value: TabFileManagerInputVo) {
    this._inputSync.next(value);
  }

  @Input() userRole: RolesEnum;

  ecomId;
  currentTaskID: number;
  currentFileID: number;

  @Input() comments: any;

  conType: string;
  tabIndex: number;
  expanded = true;

  @Output() removeTabCallback: EventEmitter<TabFileDelTaskfileOutVo> = new EventEmitter<TabFileDelTaskfileOutVo>();
  @Output() setTabNameCallback: EventEmitter<TabFileManagerOutputVo> = new EventEmitter<TabFileManagerOutputVo>();
  @Output() settingsSavedCallback: EventEmitter<TabFileSettsavedOutVo> = new EventEmitter<TabFileSettsavedOutVo>();
  @Output() saveClicked: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('prodnodetree_container', { read: ViewContainerRef, static: true })
  prodNodeTreeContainer: ViewContainerRef;

  @ViewChild('fileupload_container', { read: ViewContainerRef, static: true })
  fileUploadContainer: ViewContainerRef;

  @ViewChild('scheduleComponent', { static: true }) scheduleComponent: ScheduleComponent;

  @ViewChild('accordion', { static: true }) accordion: MatAccordion;

  /** HIDE FILE SETTINGS CONTAINER**/
  hideFileSettingsContainer = true;
  /** HIDE FILE UPLOAD CONTAINER**/
  hideFileUploadContainer = true;
  /** DISABLE FILE DELETE BUTTON IN FILE SETTINGS PANEL**/
  disableFileDeleteBtn = false;
  /** DISABLE FILE SETTINGS SAVE BUTTON IN FILE SETTINGS PANEL**/
  disableFileSettingsSaveBtn = true;
  /** HIDE ACTION PANEL (DELETE, SAVE BUTTONS) IN FILE SETTINGS PANEL**/
  hideActionPanel = true;
  /** TOGGLE THE FILE SETTINGS PANEL**/
  isExpanded = true;

  taskFile: TaskFile = null;

  settingsComponent: SettingsInterface = null;
  shopifyComponent: EcomInterface = null;
  Constants = Constants;

  saveFile = false;
  private _unsubscribeAll: Subject<void>;
  private subs: Subscription = new Subscription();

  constructor(
    private dialog: MatDialog,
    private cfr: ComponentFactoryResolver,
    private fileSettingsService: FileSettingsService,
    private taskWizardUpdateService: TaskwizardUpdateService,
    private taskWizardService: TaskwizardService,
    private watcherService: TaskWatcherService,
    private _translateService: TranslateService,
    public taskWizardSampleService: TaskWizardSampleService,
    private scrollService: ScrollService
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this._inputSync.subscribe((inputValue: TabFileManagerInputVo) => {
      if (Utils.isNullOrUndefined(inputValue)) {
        return;
      }
      this.tabIndex = inputValue.tabIndex;
      this.conType = inputValue.conType;
      this.currentTaskID = inputValue.taskId;

      console.log(inputValue);

      if (Utils.isNullOrUndefined(inputValue.taskFile)) {
        const inputFUDVo = new FileUploadDownloadInputVo();
        inputFUDVo.taskId = this.currentTaskID;
        switch (this.conType) {
          case Constants.FTP_CONNECTION_TYPE:
            setTimeout(() => this.initFTPUploadComponent());
            break;
          case Constants.FILE_UPLOAD_CONNECTION_TYPE:
            this.initFileUploadComponent(inputFUDVo);
            break;
          case Constants.URL_CONNECTION_TYPE:
            this.initURLUploadComponent(inputFUDVo);
            break;
          case Constants.SHOPIFY_CONNECTION_TYPE:
            this.initConnectShopDownloadComponent({ type: 'shopify', taskId: this.currentTaskID });
            break;
          case Constants.JUMPSELLER_CONNECTION_TYPE:
            this.initConnectShopDownloadComponent({ type: 'jumpseller', taskId: this.currentTaskID });
            break;
          case Constants.SHOPRENTER_CONNECTION_TYPE:
            this.initConnectShopDownloadComponent({ type: 'shoprenter', taskId: this.currentTaskID });
            break;
          case Constants.ECWID_CONNECTION_TYPE:
            this.initConnectShopDownloadComponent({ type: 'ecwid', taskId: this.currentTaskID });
            break;
          case Constants.BIGCOMMERCE_CONNECTION_TYPE:
            this.initConnectShopDownloadComponent({ type: 'bigcommerce', taskId: this.currentTaskID });
            break;
          case Constants.WOOCOMMERCE_CONNECTION_TYPE:
            this.initConnectShopDownloadComponent({ type: 'woocommerce', taskId: this.currentTaskID });
            break;
          case Constants.UNAS_CONNECTION_TYPE:
            this.initConnectShopDownloadComponent({ type: 'unas', taskId: this.currentTaskID });
            break;
          case Constants.EKM_CONNECTION_TYPE:
            this.initConnectShopDownloadComponent({ type: 'ekm', taskId: this.currentTaskID });
            break;
          case Constants.WIX_CONNECTION_TYPE:
            this.initConnectShopDownloadComponent({ type: 'wix', taskId: this.currentTaskID });
            break;
          case Constants.DOCS_DRIVE_CONNECTION_TYPE:
          case Constants.GOOGLE_DRIVE_CONNECTION_TYPE:
            this.initURLSharedUploadComponent(
              this.mapSharedDownloadInputVo(
                FileDownloadService.GOOGLE_DRIVE_UPLOAD_URL,
                Constants.URL_EXAMPLES[this.conType],
                this.conType,
                inputFUDVo.taskId
              )
            );
            break;
          case Constants.DROPBOX_CONNECTION_TYPE:
            this.initURLSharedUploadComponent(
              this.mapSharedDownloadInputVo(
                FileDownloadService.DROPBOX_UPLOAD_URL,
                Constants.URL_EXAMPLES[this.conType],
                this.conType,
                inputFUDVo.taskId
              )
            );
            break;
        }
        return;
      }
      // this.isExpanded = false;
      this.taskFile = inputValue.taskFile;
      const settings = this.taskFile.settings;

      this.currentFileID = this.taskFile.fileId;
      this.ecomId = settings['ecomId'] || null;

      if (inputValue.isDownloading === true && this.ecomId) {
        this.registerDownloadWatcher();
        this.saveFile = true;
        this.watcherService.addRunnedTaskToWatcher(this.currentTaskID);
      }

      const inputUDVo = this.mapUploadDownloadInputVo(
        this.currentTaskID,
        this.currentFileID,
        this.taskFile.extension,
        this.taskFile.remotepath
      );
      inputUDVo.isUpdate = true;
      switch (this.conType) {
        case Constants.FTP_CONNECTION_TYPE:
          this.initUploadedFileView(inputUDVo);
          break;
        case Constants.FILE_UPLOAD_CONNECTION_TYPE:
          this.initFileUploadComponent(inputUDVo);
          break;
        case Constants.URL_CONNECTION_TYPE:
          this.initURLUploadComponent(inputUDVo);
          break;
        case Constants.SHOPIFY_CONNECTION_TYPE:
          this.initShopDownloadComponent({ type: 'shopify', ecomId: settings['ecomId'] });
          break;
        case Constants.JUMPSELLER_CONNECTION_TYPE:
          this.initShopDownloadComponent({ type: 'jumpseller', ecomId: settings['ecomId'] });
          break;
        case Constants.SHOPRENTER_CONNECTION_TYPE:
          this.initShopDownloadComponent({ type: 'shoprenter', ecomId: settings['ecomId'] });
          break;
        case Constants.ECWID_CONNECTION_TYPE:
          this.initShopDownloadComponent({ type: 'ecwid', ecomId: settings['ecomId'] });
          break;
        case Constants.BIGCOMMERCE_CONNECTION_TYPE:
          this.initShopDownloadComponent({ type: 'bigcommerce', ecomId: settings['ecomId'] });
          break;
        case Constants.WOOCOMMERCE_CONNECTION_TYPE:
          this.initShopDownloadComponent({ type: 'woocommerce', ecomId: settings['ecomId'] });
          break;
        case Constants.WIX_CONNECTION_TYPE:
          this.initShopDownloadComponent({ type: 'wix', ecomId: settings['ecomId'] });
          break;
        case Constants.UNAS_CONNECTION_TYPE:
          this.initShopDownloadComponent({ type: 'unas', ecomId: settings['ecomId'] });
          break;
        case Constants.EKM_CONNECTION_TYPE:
          this.initShopDownloadComponent({ type: 'ekm', ecomId: settings['ecomId'] });
          break;
        case Constants.SQUARESPACE_CONNECTION_TYPE:
          this.initShopDownloadComponent({ type: 'squarespace', ecomId: settings['ecomId'] });
          break;
        case Constants.DOCS_DRIVE_CONNECTION_TYPE:
        case Constants.GOOGLE_DRIVE_CONNECTION_TYPE:
          this.initURLSharedUploadComponent(
            this.mapSharedDownloadInputVo(
              FileDownloadService.GOOGLE_DRIVE_UPLOAD_URL,
              Constants.URL_EXAMPLES[this.conType],
              this.conType,
              this.currentTaskID,
              this.currentFileID,
              this.taskFile.extension,
              this.taskFile.remotepath
            )
          );
          break;
        case Constants.DROPBOX_CONNECTION_TYPE:
          this.initURLSharedUploadComponent(
            this.mapSharedDownloadInputVo(
              FileDownloadService.DROPBOX_UPLOAD_URL,
              Constants.URL_EXAMPLES[this.conType],
              this.conType,
              this.currentTaskID,
              this.currentFileID,
              this.taskFile.extension,
              this.taskFile.remotepath
            )
          );
          break;
      }
      switch (this.taskFile.extension) {
        case Constants.EXTENSION_JSON:
        case Constants.EXTENSION_XML:
          const settingsInput = this.mapProdNodeTreeSettingsVO(
            this.taskFile.taskId,
            this.taskFile.fileId,
            this.taskFile.extension,
            null,
            !Utils.isNullOrUndefined(settings) ? settings : null,
            !Utils.isNullOrUndefined(settings) ? settings.container : null,
            settings.encoding
          );
          this.initJSONXMLSettings(settingsInput);
          break;
        case Constants.EXTENSION_CSV:
          let csvSettingsInput;
          if (!Utils.isNullOrUndefined(settings)) {
            csvSettingsInput = this.mapCsvSettings(
              this.taskFile.extension,
              settings.firstRowIsHeader,
              settings.startRowNum,
              settings.delimiter,
              settings.enclosure,
              settings.eol,
              settings.encoding
            );
          } else {
            csvSettingsInput = this.mapCsvSettings(this.taskFile.extension);
          }
          this.initCSVSettings(csvSettingsInput);
          break;
        case Constants.EXTENSION_XLS:
        case Constants.EXTENSION_XLSX:
          let excelSettingsInput;
          if (!Utils.isNullOrUndefined(settings)) {
            excelSettingsInput = this.mapExcelSettings(
              this.taskFile.taskId,
              this.taskFile.fileId,
              this.taskFile.extension,
              settings.firstRowIsHeader,
              settings.startRowNum,
              settings.sheet,
              settings.encoding
            );
          } else {
            excelSettingsInput = this.mapExcelSettings(
              this.taskFile.taskId,
              this.taskFile.fileId,
              this.taskFile.extension
            );
          }
          this.initExcelSettings(excelSettingsInput);
          break;
      }
    });
  }

  registerDownloadWatcher(): void {
    this.watcherService.startEcomDownloaderWatcher();
    this.subs.add(
      this.watcherService
        .getTaskRunFinishedObservable()
        .pipe(
          take(1),
          switchMap(() => {
            return this.fileSettingsService.getDownloadedDataInfo(this.currentFileID, this.ecomId);
          })
        )
        .subscribe((resp) => {
          this.saveEcomMapping(resp);
        })
    );
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
    this.subs.unsubscribe();
  }

  initFTPUploadComponent(isUpdate = false): void {
    console.log('initFTPUploadComponent');
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.width = '80%';
    dialogConfig.data = new FtpDialogVO(this.currentTaskID, this.currentFileID, isUpdate);

    const dialogRef = this.dialog.open(FtpclientComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result: FtpUploadResponseVO) => {
      if (!result.status && result.deleteTab) {
        this.deleteTabFile(true);
        return;
      } else if (result.status) {
        this.ftpComponentCallback(result);
      }
    });
  }

  initURLUploadComponent(inputVo: FileUploadDownloadInputVo): void {
    console.log('initURLUploadComponent');
    const instance = this.initSharedUploadFileComponent(UrlUploadComponent);
    instance.callBack.subscribe((resp: FileUploadResponseVo) => this.urlUploadComponentCallback(resp));
    instance.downloadFileActive
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((resp: boolean) => (this.saveFile = resp));
    instance.selectedTypeCallBack.subscribe((resp: string) => this.fileTypeChanged(resp));
    instance.inputs = inputVo;
    this.hideFileUploadContainer = false;
    this.hideActionPanel = false;
  }

  initURLSharedUploadComponent(inputVo: SharedUrlUploadInputVo): void {
    console.log('initURLSharedUploadComponent');
    const instance = this.initSharedUploadFileComponent(UrlSharedComponent);
    instance.callBack.subscribe((resp: FileUploadResponseVo) => this.urlUploadComponentCallback(resp));
    instance.downloadFileActive
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((resp: boolean) => (this.saveFile = resp));
    instance.selectedTypeCallBack.subscribe((resp: string) => this.fileTypeChanged(resp));
    instance.inputs = inputVo;
    this.hideFileUploadContainer = false;
    this.hideActionPanel = false;
  }

  initFileUploadComponent(inputVo: FileUploadDownloadInputVo): void {
    console.log('initFileUploadComponent');
    const instance = this.initSharedUploadFileComponent(FileUploadComponent);
    instance.callBack.subscribe((resp: FileUploadResponseVo) => this.fileUploadComponentCallback(resp));
    instance.downloadFileActive
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((resp: boolean) => (this.saveFile = resp));
    instance.selectedTypeCallBack.subscribe((resp: string) => this.fileTypeChanged(resp));
    instance.inputs = inputVo;
    this.hideFileUploadContainer = false;
    this.hideActionPanel = false;
  }

  initShopDownloadComponent(inputVo): void {
    console.log('initShopifyComponent');
    const instance = this.initSharedUploadFileComponent(StoreConnectedComponent);
    instance.inputs = inputVo as StoreConnectedComponentInput;
    instance.inputs.role = this.userRole;
    this.shopifyComponent = instance;
    this.hideFileUploadContainer = false;
    this.hideActionPanel = false;
    this.disableFileSettingsSaveBtn = false;
  }

  initConnectShopDownloadComponent(inputVo): void {
    console.log('initConnectStoreComponent');
    const instance = this.initSharedUploadFileComponent(ConnectStoreComponent);
    instance.inputs = inputVo as ConnectStoreComponentInput;
    instance.inputs.role = this.userRole;
    this.shopifyComponent = instance;
    this.hideFileUploadContainer = false;
    this.hideActionPanel = false;
    this.disableFileSettingsSaveBtn = false;
  }

  initUploadedFileView(inputVo: FileUploadDownloadInputVo): void {
    console.log('initUploadedFileView');
    const instance = this.initSharedUploadFileComponent(PreviewDownloadedFileComponent);
    instance.selectedTypeCallBack.subscribe((resp: string) => this.fileTypeChanged(resp));
    instance.initFTPUpload.subscribe((resp: boolean) => this.initFTPUploadComponent(resp));
    instance.inputs = inputVo;
    this.hideFileUploadContainer = false;
    this.hideActionPanel = false;
  }

  initSharedUploadFileComponent(component: Type<any>): any {
    this.fileUploadContainer.clear();
    const factory = this.cfr.resolveComponentFactory(component);
    return this.fileUploadContainer.createComponent(factory).instance;
  }

  urlUploadComponentCallback(fileUploadResponseVO: FileUploadResponseVo): void {
    this.emitTabName(this.tabIndex, fileUploadResponseVO.remotePath);
    this.currentFileID = fileUploadResponseVO.fileId;
    this.fileTypeChanged(fileUploadResponseVO.detectedType);
  }

  ftpComponentCallback(fileUploadResponseVO: FileUploadResponseVo): void {
    this.emitTabName(this.tabIndex, fileUploadResponseVO.remotePath);
    this.currentFileID = fileUploadResponseVO.fileId;
    const inputVo = this.mapUploadDownloadInputVo(
      this.currentTaskID,
      this.currentFileID,
      fileUploadResponseVO.detectedType,
      fileUploadResponseVO.remotePath
    );
    this.initUploadedFileView(inputVo);
    this.fileTypeChanged(fileUploadResponseVO.detectedType);
    this.hideActionPanel = false;
  }

  fileUploadComponentCallback(fileUploadResponseVO: FileUploadResponseVo): void {
    this.currentFileID = fileUploadResponseVO.fileId;
    this.fileTypeChanged(fileUploadResponseVO.detectedType);
    this.emitTabName(this.tabIndex, fileUploadResponseVO.remotePath);
  }

  emitTabName(index: number, tabName: string): void {
    tabName = Utils.getFileNameFromPath(tabName);
    const tabFileManagerVo = new TabFileManagerOutputVo();
    tabFileManagerVo.tabName = tabName;
    tabFileManagerVo.tabIndex = index;
    this.setTabNameCallback.emit(tabFileManagerVo);
  }

  fileTypeChanged(fileType): void {
    if (Utils.isNullOrUndefined(this.currentFileID)) {
      return;
    }
    if (!Utils.isNullOrUndefined(this.settingsComponent)) {
      if (this.settingsComponent.getSupportedFileTypes().indexOf(fileType) > -1) {
        this.settingsComponent.refresh(fileType);
        return;
      }
      console.log('fileTypeChanged');
      const dialogConfig = new MatDialogConfig();
      dialogConfig.autoFocus = true;
      dialogConfig.width = '400px';
      dialogConfig.panelClass = 'custom-modal-container';
      const dialogRef = this.dialog.open(ChooseSettingsModalComponent, dialogConfig);
      dialogRef.afterClosed().subscribe((result) => {
        if (result !== true) {
          console.log('waaaat');
          this.changeSettings(fileType);
        } else {
          this.settingsComponent.refresh(fileType);
        }
      });
    } else {
      this.changeSettings(fileType);
    }
  }

  changeSettings(fileType): void {
    switch (fileType) {
      case Constants.EXTENSION_CSV:
        const csvInputVo = this.mapCsvSettings(fileType);
        this.initCSVSettings(csvInputVo);
        break;
      case Constants.EXTENSION_JSON:
      case Constants.EXTENSION_XML:
        const prodNodeTreeVO = this.mapProdNodeTreeSettingsVO(this.currentTaskID, this.currentFileID, fileType);
        this.initJSONXMLSettings(prodNodeTreeVO);
        break;
      case Constants.EXTENSION_XLSX:
      case Constants.EXTENSION_XLS:
        const excelInputVo = this.mapExcelSettings(this.currentTaskID, this.currentFileID, fileType);
        this.initExcelSettings(excelInputVo);
        break;
    }
  }

  initExcelSettings(excelSettingsInputVo: ExcelSettingsInputVo): void {
    this.prodNodeTreeContainer.clear();
    const factory = this.cfr.resolveComponentFactory(ExcelSettingsComponent);
    const instance = this.prodNodeTreeContainer.createComponent(factory).instance;
    this.settingsComponent = instance;
    instance.inputs = excelSettingsInputVo;
    this.hideFileSettingsContainer = false;
    this.disableFileSettingsSaveBtn = false;
  }

  initCSVSettings(csvSettingsInputVo: CsvSettingsInputVo): void {
    this.prodNodeTreeContainer.clear();
    const factory = this.cfr.resolveComponentFactory(CsvSettingsComponent);
    const instance = this.prodNodeTreeContainer.createComponent(factory).instance;
    this.settingsComponent = instance;
    instance.inputs = csvSettingsInputVo;
    this.hideFileSettingsContainer = false;
    this.disableFileSettingsSaveBtn = false;
  }

  initJSONXMLSettings(prodNodeTreeVO: ProductNodeTreeVO): void {
    const factory = this.cfr.resolveComponentFactory(ProductnodetreeComponent);
    this.prodNodeTreeContainer.clear();
    const instance = this.prodNodeTreeContainer.createComponent(factory).instance;
    this.settingsComponent = instance;
    instance.inputs = prodNodeTreeVO;
    this.hideFileSettingsContainer = false;
    this.disableFileSettingsSaveBtn = false;
  }

  mapSharedDownloadInputVo(
    restApiUrl: string,
    urlPlaceholder: string,
    urlSourceType: string,
    taskId: number,
    fileId: number = null,
    type: string = null,
    path: string = null
  ): SharedUrlUploadInputVo {
    const inputVo = new SharedUrlUploadInputVo();
    inputVo.placeholder = urlPlaceholder;
    inputVo.taskId = taskId;
    inputVo.fileId = fileId;
    inputVo.selectedType = type;
    inputVo.remotePath = path;
    inputVo.restUrl = restApiUrl;
    inputVo.urlSourceType = urlSourceType;
    return inputVo;
  }

  mapUploadDownloadInputVo(
    taskId: number,
    fileId: number = null,
    type: string = null,
    path: string = null
  ): FileUploadDownloadInputVo {
    const inputVo = new FileUploadDownloadInputVo();
    inputVo.taskId = taskId;
    inputVo.fileId = fileId;
    inputVo.selectedType = type;
    inputVo.remotePath = path;
    return inputVo;
  }

  mapCsvSettings(
    fileType: string,
    firstRowHeader: boolean = null,
    startRowNum: number = null,
    delimiter: string = null,
    enclosure: string = null,
    endofline: string = null,
    encoding = 'UTF-8'
  ): CsvSettingsInputVo {
    const inputVo = new CsvSettingsInputVo();
    inputVo.fileType = fileType;
    inputVo.firstRowIsHeader = firstRowHeader;
    inputVo.startRowNum = startRowNum;
    inputVo.delimiter = delimiter;
    inputVo.enclosure = enclosure;
    inputVo.endofline = endofline;
    inputVo.encoding = encoding;
    return inputVo;
  }

  mapExcelSettings(
    taskId: number,
    fileId: number = null,
    fileType: string,
    firstRowHeader: boolean = null,
    startRowNum: number = null,
    sheet: string = null,
    encoding = 'UTF-8'
  ): ExcelSettingsInputVo {
    const inputVo = new ExcelSettingsInputVo();
    inputVo.taskId = taskId;
    inputVo.fileId = fileId;
    inputVo.fileType = fileType;
    inputVo.firstRowIsHeader = firstRowHeader;
    inputVo.startRowNum = startRowNum;
    inputVo.sheet = sheet;
    inputVo.encoding = encoding;
    return inputVo;
  }

  mapProdNodeTreeSettingsVO(
    taskId: number,
    fileId: number,
    fileType: string,
    nodeTree: any[] = null,
    settings: any[] = null,
    selectedNode: string[] = null,
    encoding = null
  ): ProductNodeTreeVO {
    const inputVo = new ProductNodeTreeVO();
    inputVo.taskId = taskId;
    inputVo.fileId = fileId;
    inputVo.fileType = fileType;
    inputVo.nodeTree = nodeTree;
    inputVo.settings = settings;
    inputVo.selectedNode = selectedNode;
    inputVo.encoding = encoding;
    return inputVo;
  }

  deleteTabFile(force = false): void {
    console.log('deleteTabFile');
    const outputVo = new TabFileDelTaskfileOutVo();
    outputVo.tabIndex = this.tabIndex;
    outputVo.fileId = this.currentFileID;
    outputVo.taskId = this.currentTaskID;
    if (force) {
      this.removeTabCallback.emit(outputVo);
      return;
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.width = '400px';
    dialogConfig.data = new DeleteModalVO(
      this._translateService.instant('TASKWIZARD.ADDFILES.MAPPING.SOURCE_SETTINGS.DELETE_MODAL_TITLE'),
      this._translateService.instant('TASKWIZARD.ADDFILES.MAPPING.SOURCE_SETTINGS.DELETE_MODAL_MESSAGE'),
      this._translateService.instant('TASKWIZARD.ADDFILES.MAPPING.SOURCE_SETTINGS.DELETE_MODAl_DELETE_MESSAGE'),
      this._translateService.instant('TASKWIZARD.ADDFILES.MAPPING.SOURCE_SETTINGS.DELETE_MODAl_DELETE_STRING')
    );
    dialogConfig.panelClass = 'custom-modal-container';
    const dialogRef = this.dialog.open(DeleteModalComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.removeTabCallback.emit(outputVo);
      }
    });
  }

  refreshSettings(): void {
    this.settingsComponent.update();
  }

  closeSettingsAndScrollTop(): void {
    this.scrollService.scrollToTop();
    this.expanded = false;
  }

  /**Save file settings and also shopify store settings, and send back fileId to addFiles component*/
  saveFileSettings(): void {
    this.closeSettingsAndScrollTop();

    this.saveClicked.emit();
    this.saveScheduler();
    this.saveFile = true;
    console.log('saveFile', this.saveFile);
    if (!Utils.isNullOrUndefined(this.shopifyComponent)) {
      const result = this.shopifyComponent.save();
      this.ecomId = result.ecomId;
      this.subs.add(
        this.fileSettingsService.saveEcomSettings(this.currentTaskID, this.currentFileID, result.ecomId).subscribe(
          (resp) => {
            this.currentFileID = resp.fileId;
            this.registerDownloadWatcher();
            this.watcherService.addRunnedTaskToWatcher(this.currentTaskID);
          },
          (error) => (this.saveFile = false)
        )
      );
      return;
    }
    if (Utils.isNullOrUndefined(this.settingsComponent)) {
      return;
    }
    const settOutputVo = this.settingsComponent.getSettings();
    this.subs.add(
      this.fileSettingsService
        .saveSettings(this.currentTaskID, this.currentFileID, settOutputVo.fileType, settOutputVo.settings)
        .subscribe(
          (res: boolean) => {
            this.saveFile = false;
            if (res === true) {
              const settingsSavedVO = new TabFileSettsavedOutVo(
                this.tabIndex,
                this.currentFileID,
                settOutputVo.fileType
              );
              this.isExpanded = false;
              this.settingsSavedCallback.emit(settingsSavedVO);
            }
          },
          () => (this.saveFile = false)
        )
    );
  }

  saveEcomMapping(resp): void {
    this.saveFile = false;
    this.emitTabName(this.tabIndex, resp.domain);

    const settingsSavedVO = new TabFileSettsavedOutVo(this.tabIndex, this.currentFileID);
    this.isExpanded = false;
    let template = null;
    let currency = resp['shopCurrency'] || 'USD';
    let replacedTmp = null;
    switch (resp.ecomType) {
      case 'shopify':
        currency = currency.toUpperCase();
        replacedTmp = Constants.SHOPIFY_MAPPING_TEMPLATE.replace(/#####/g, this.currentFileID.toString());
        replacedTmp = replacedTmp.replace(/###C###/g, currency);
        template = JSON.parse(replacedTmp);
        break;
      case 'jumpseller':
        currency = currency.toUpperCase();
        replacedTmp = Constants.JUMPSELLER_MAPPING_TEMPLATE.replace(/#####/g, this.currentFileID.toString());
        replacedTmp = replacedTmp.replace(/###C###/g, currency);
        template = JSON.parse(replacedTmp);
        break;
      case 'shoprenter':
        currency = currency['code'].toUpperCase();
        replacedTmp = Constants.SHOPRENTER_MAPPING_TEMPLATE.replace(/#####/g, this.currentFileID.toString());
        replacedTmp = replacedTmp.replace(/###C###/g, currency);
        template = JSON.parse(replacedTmp);
        break;
      case 'ecwid':
        currency = currency.toUpperCase();
        replacedTmp = Constants.ECWID_MAPPING_TEMPLATE.replace(/#####/g, this.currentFileID.toString());
        replacedTmp = replacedTmp.replace(/###C###/g, currency);
        template = JSON.parse(replacedTmp);
        break;
      case 'bigcommerce':
        replacedTmp = Constants.BIGCOMMERCE_MAPPING_TEMPLATE.replace(/#####/g, this.currentFileID.toString());
        replacedTmp = replacedTmp.replace(/###C###/g, currency);
        template = JSON.parse(replacedTmp);
        // template = JSON.parse(Constants.BIGCOMMERCE_MAPPING_TEMPLATE.replace(/#####/g, this.currentFileID.toString()));
        break;
      case 'wix':
        replacedTmp = Constants.WIX_MAPPING_TEMPLATE.replace(/#####/g, this.currentFileID.toString());
        replacedTmp = replacedTmp.replace(/###C###/g, currency);
        template = JSON.parse(replacedTmp);
        // template = JSON.parse(Constants.BIGCOMMERCE_MAPPING_TEMPLATE.replace(/#####/g, this.currentFileID.toString()));
        break;
      case 'woocommerce':
        replacedTmp = Constants.WOOCOMMERCE_MAPPING_TEMPLATE.replace(/#####/g, this.currentFileID.toString());
        replacedTmp = replacedTmp.replace(/###C###/g, currency);
        template = JSON.parse(replacedTmp);
        // template = JSON.parse(Constants.BIGCOMMERCE_MAPPING_TEMPLATE.replace(/#####/g, this.currentFileID.toString()));
        break;
      case 'unas':
        replacedTmp = Constants.UNAS_MAPPING_TEMPLATE.replace(/#####/g, this.currentFileID.toString());
        replacedTmp = replacedTmp.replace(/###C###/g, currency);
        template = JSON.parse(replacedTmp);
        // template = JSON.parse(Constants.BIGCOMMERCE_MAPPING_TEMPLATE.replace(/#####/g, this.currentFileID.toString()));
        break;
      case 'ekm':
        replacedTmp = Constants.EKM_MAPPING_TEMPLATE.replace(/#####/g, this.currentFileID.toString());
        replacedTmp = replacedTmp.replace(/###C###/g, currency);
        template = JSON.parse(replacedTmp);
        // template = JSON.parse(Constants.BIGCOMMERCE_MAPPING_TEMPLATE.replace(/#####/g, this.currentFileID.toString()));
        break;
      case 'squarespace':
        replacedTmp = Constants.SQUARESPACE_MAPPING_TEMPLATE.replace(/#####/g, this.currentFileID.toString());
        replacedTmp = replacedTmp.replace(/###C###/g, currency);
        template = JSON.parse(replacedTmp);
        // template = JSON.parse(Constants.BIGCOMMERCE_MAPPING_TEMPLATE.replace(/#####/g, this.currentFileID.toString()));
        break;
    }

    if (template) {
      if (this.taskFile) {
        this.settingsSavedCallback.emit(settingsSavedVO);
        return;
      }
      this.subs.add(
        this.taskWizardService
          .saveMapping(this.currentTaskID, template, { mappingTemplate: null }, false)
          .subscribe(() => {
            this.taskWizardUpdateService.init(this.currentTaskID).subscribe(() => {
              this.settingsSavedCallback.emit(settingsSavedVO);
            });
          })
      );
    } else {
      this.taskWizardUpdateService.init(this.currentTaskID).subscribe(() => {
        this.settingsSavedCallback.emit(settingsSavedVO);
      });
    }
  }

  saveScheduler(): void {
    this.scheduleComponent.saveStep().subscribe(() => {});
  }

  changeAllExpansionPanel(event): void {
    if (event) {
      this.accordion.openAll();
    } else {
      this.accordion.closeAll();
    }
  }

  isSettingsHidden(): boolean {
    return (
      this.hideFileSettingsContainer ||
      [
        Constants.SHOPIFY_CONNECTION_TYPE,
        Constants.SHOPRENTER_CONNECTION_TYPE,
        Constants.JUMPSELLER_CONNECTION_TYPE,
        Constants.ECWID_CONNECTION_TYPE,
        Constants.BIGCOMMERCE_CONNECTION_TYPE,
        Constants.WOOCOMMERCE_CONNECTION_TYPE,
        Constants.UNAS_CONNECTION_TYPE,
        Constants.EKM_CONNECTION_TYPE,
        Constants.WIX_CONNECTION_TYPE,
        Constants.SQUARESPACE_CONNECTION_TYPE,
      ].includes(this.conType)
    );
  }
}

import { EcomTypeEnum } from '../../vo/enums/ecom-type-enum';

export interface PlatformItem {
  active: boolean;
  key: EcomTypeEnum;
  name: string;
  images: PlatformItemImages;
  options: PlatformItemOption;
  reviewBadge?: PlatformItemReviewBadge;
  install: PlatformItemInstall;
  task: PlatformItemTask;
  links: PlatformItemLinks;
  transparentIconSrc: string;
}

export interface PlatformItemImages {
  logo: string;
  black_logo?: string;
}

export interface PlatformItemReviewBadge {
  image: string;
  redirectLink: string;
}

export interface PlatformItemInstall {
  canInstall: boolean;
  title: string;
  description: string;
  descriptionLong: string;
  logoIconPath: string;
  url: string;
  needDialogForInstall: boolean;
  learnMoreUrlRetailer: string;
}

export interface PlatformItemOption {
  hasSupplierApp: boolean;
  hasRetailerApp: boolean;
  hasDataFeed: boolean;
  hasFreeTrial: boolean;
  beta: boolean;
  datafeedIntegration?: {
    [key in DATAFEED_INTEGRATIONS]: boolean;
  };
}

export interface PlatformItemTask {
  taskConnectionType: string;
  mappingTemplate: string;
}

export interface PlatformItemLinks {
  getLinkToOrder?: (payload: PlatformItemLinkOrderPayload) => string;
  getLinkToProduct?: (payload: PlatformItemLinkProductPayload) => string;
}

export interface PlatformItemLinkOrderPayload {
  domain: string;
  storeId: string;
  platformOrderId: string;
}

export interface PlatformItemLinkProductPayload {
  domain: string;
  storeId: string;
  productId: string;
}

export enum DATAFEED_INTEGRATIONS {
  HAS_IMPORT = 'hasImport',
  HAS_EXPORT = 'hasExport',
  HAS_VU = 'hasVU',
}

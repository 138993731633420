import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RestService } from '../rest/rest.service';
import { HttpParams } from '@angular/common/http';
import { AlibabaProductsInfoResp } from '../../main/alibaba-new/vo/alibaba-vo';
import { AlibabaFreightCalculation } from 'app/main/alibaba-new/models/freight-calculation-data';

@Injectable({
  providedIn: 'root',
})
export class AlibabaService {
  constructor(private restService: RestService) {}

  public addProducts(productIds: number[]): Observable<void> {
    return this.restService.post(`AlibabaProductService/addProducts`, { productIds }).pipe(
      map((response) => {
        return;
      })
    );
  }

  public getUserToken(): Observable<any> {
    return this.restService.get(`AlibabaTokenService/getUserToken`).pipe(map((response) => response.getFirstData()));
  }

  public getEcologyToken(): Observable<any> {
    return this.restService.get(`AlibabaTokenService/getEcologyToken`).pipe(map((response) => response.getFirstData()));
  }

  public getProductInfo(ecomId, searchString, from, size): Observable<AlibabaProductsInfoResp> {
    const httpParams = new HttpParams()
      .set('ecomId', ecomId)
      .set('search', searchString ? searchString : '*')
      .set('from', from)
      .set('size', size);

    return this.restService
      .get('AlibabaProductService/getProductInfo', httpParams)
      .pipe(map((response) => response.getFirstData()));
  }

  public getAlibabaCatalogs(ecomId): Observable<any> {
    const httpParams = new HttpParams().set('ecomId', ecomId).set('alibabaCatalog', 'true');
    return this.restService
      .get('RetailerCatalogService/getRetailersCatalogs', httpParams)
      .pipe(map((response) => response.getData()));
  }

  public calculateShipping(
    destCountry: string,
    productId: string,
    qty: string,
    dispatchCountry: string
  ): Observable<AlibabaFreightCalculation> {
    const httpParams = new HttpParams()
      .set('destinationCountry', destCountry)
      .set('productId', productId)
      .set('quantity', qty)
      .set('dispatchLocation', dispatchCountry);
    return this.restService
      .get('AlibabaProductService/calculateShipping', httpParams)
      .pipe(map((response) => response.getFirstData()));
  }

  public removeFromImportList(productIDs): Observable<any> {
    return this.restService
      .delete('AlibabaProductService/removeFromImportList', { productIds: productIDs })
      .pipe(map((response) => response.getData()));
  }

  public getOrders(param): Observable<any> {
    const httpParams = new HttpParams()
      .set('ecomId', param.ecomIds[0])
      .set('fromDate', param.dateFrom)
      .set('toDate', param.dateTo)
      .set('search', param.searchString)
      .set('status', param.synceeStatus);

    return this.restService
      .get('AlibabaOrderService/getOrders', httpParams)
      .pipe(map((response) => response.getFirstData()));
  }

  public accountDelete(): Observable<any> {
    return this.restService.delete('AlibabaAccountService/delete').pipe(map((res) => res.getData()));
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { AIChatMessage } from '../../../model/messages/ai-chat-message';
import { AiChatMessagesService } from '../../../services/ai-chat-messages.service';

@Component({
  selector: 'app-ai-chat-message-filter',
  templateUrl: './ai-chat-message-filter.component.html',
  styleUrls: ['./ai-chat-message-filter.component.scss'],
})
export class AiChatMessageFilterComponent implements OnInit {
  @Input() message: AIChatMessage;
  @Input() isLast: boolean;
  filterLength: number;

  constructor(private aiChatMessagesService: AiChatMessagesService) {}

  ngOnInit(): void {
    this.filterLength = this.getFilterLength();
  }

  getFilterLength(): number {
    return Object.keys(this.message.payload.filter).filter((key) => !['search', 'category'].includes(key)).length;
  }

  filterClicked(): void {
    if (this.isLast) {
      return;
    }
    this.aiChatMessagesService.pushMessage(this.message);
  }
}

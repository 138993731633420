import { Pipe, PipeTransform } from '@angular/core';
import { getPlatformByType } from '../../utils/platforms/platform-config';
import { EcomTypeEnum } from '../../vo/enums/ecom-type-enum';
import { DATAFEED_INTEGRATIONS } from '../../utils/platforms/platform-item';

@Pipe({
  name: 'hasDatafeedIntegration',
  standalone: true,
})
export class HasDatafeedIntegrationPipe implements PipeTransform {
  constructor() {}
  transform(ecomType: string | EcomTypeEnum, type: DATAFEED_INTEGRATIONS): boolean {
    if (!ecomType) {
      return false;
    }

    const platformConfig = getPlatformByType(ecomType as EcomTypeEnum);

    return (
      platformConfig?.options.hasDataFeed &&
      platformConfig?.options.datafeedIntegration &&
      platformConfig?.options.datafeedIntegration[type]
    );
  }
}

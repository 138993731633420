import { CommonModule, NgOptimizedImage } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { ImageFallbackDirective } from 'app/shared/directives/image-fallback.directive';
import { SlickCarouselComponent, SlickCarouselModule } from 'ngx-slick-carousel';
import { RouterLink } from '@angular/router';
import { BreakPoint } from '../../../service/screen-manager/screen-manager.service';
import { ProductImageFullCdnPipe, ProductImageThumbnailCdnPipe } from '../../pipes/product-image-resizer-cdn.pipe';
import { ProductImageErrorHandlerDirective } from '../../directives/product-image-error-handler.directive';
import { LazyLoadImageModule } from 'ng-lazyload-image';

const INITIAL_DATA: JQuerySlickOptions = {
  slidesToShow: 5,
  slidesToScroll: 5,
  speed: 200,
  vertical: true,
  infinite: false,
  arrows: false,
  responsive: [
    {
      breakpoint: BreakPoint.md,
      settings: {
        slidesToShow: 5,
        vertical: false,
      },
    },
  ],
};

@Component({
  selector: 'app-custom-thumbnail',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    FlexModule,
    SlickCarouselModule,
    ImageFallbackDirective,
    RouterLink,
    NgOptimizedImage,
    ProductImageFullCdnPipe,
    ProductImageErrorHandlerDirective,
    ProductImageThumbnailCdnPipe,
    LazyLoadImageModule,
  ],
  templateUrl: './custom-thumbnail.component.html',
  styleUrls: ['./custom-thumbnail.component.scss'],
})
export class CustomThumbnailComponent implements OnInit, OnChanges {
  @ViewChild('slickModal') slick: SlickCarouselComponent;
  @ViewChildren('slickItems') slickItems: QueryList<ElementRef>;
  @Input() images: string[];
  @Input() altForAllImages: string;
  @Input() config: JQuerySlickOptions = INITIAL_DATA;
  @Input() isMobile = false;
  @Output() indexChange = new EventEmitter<number>();

  currentImageIndex = 0;
  prevDisabled = true;

  nextDisabled = false;

  constructor() {}

  ngOnInit(): void {
    if (this.images) {
      this.nextDisabled = this.images.length <= this.config.slidesToShow;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {}

  handleImageChanged(event): void {
    this.nextDisabled = this.config.slidesToShow + event.nextSlide >= this.images.length;
    this.prevDisabled = event.nextSlide === 0;
    this.currentImageIndex = event.nextSlide;
    this.indexChange.emit(event.nextSlide);
  }

  handleSlideClicked(index: number): void {
    this.currentImageIndex = index;
    this.indexChange.emit(index);
  }

  public handleNavigation(direction: NavDirection): void {
    if (direction === 'prev') {
      this.slick.slickPrev();
    } else if (direction === 'next') {
      this.slick.slickNext();
    }
  }
}

export type NavDirection = 'prev' | 'next';

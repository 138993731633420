import { Component, OnInit } from '@angular/core';
import { AiChatService } from '../../services/ai-chat.service';
import { AiChatLayout } from '../../model/layout/ai-chat-layout';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BreakPoint, ScreenManagerService } from '../../../../service/screen-manager/screen-manager.service';

@Component({
  selector: 'app-ai-chat-header',
  templateUrl: './ai-chat-header.component.html',
  styleUrls: ['./ai-chat-header.component.scss'],
})
export class AiChatHeaderComponent implements OnInit {
  aiChatLayoutEnum = AiChatLayout;
  layoutChangeIcon: Observable<string>;

  constructor(public aiChatService: AiChatService, private screenManagerService: ScreenManagerService) {}

  ngOnInit(): void {
    this.layoutChangeIcon = this.getLayoutChangerIcon();
  }

  changeLayout(): void {
    switch (this.aiChatService.layout) {
      case AiChatLayout.SIDEBAR:
        this.aiChatService.changeLayout(AiChatLayout.POPOVER);
        break;
      case AiChatLayout.POPOVER:
        this.aiChatService.changeLayout(AiChatLayout.SIDEBAR);
        break;
    }
  }

  private getLayoutChangerIcon(): Observable<string> {
    return combineLatest([
      this.aiChatService.layout$,
      this.screenManagerService.observeBreakpoint(BreakPoint.xd).pipe(this.screenManagerService.stateMatchesOperator()),
    ]).pipe(
      map(([layout, match]) => {
        switch (true) {
          case layout === AiChatLayout.SIDEBAR:
            return 'close_fullscreen';
          case layout === AiChatLayout.POPOVER && !match:
            return 'view_sidebar';
          default:
            return null;
        }
      })
    );
  }
}

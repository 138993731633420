<app-wizard-category-mapping
  *ngIf="!hasNoSettings; else emptyState"
  [categoryItems]="categoryMappingItems"
  (showProducts)="openProductList($event.name)"
  (removeInBulk)="removeInBulk($event)"
  (removeSingleMapping)="removeMapping($event)"
  (addSingleMapping)="addCategory($event)"
  (clearSelectedMapping)="clearCategoryMapping($event)"
  (addSelectedMapping)="addInBulk($event)"
  [originalCategoriesTitle]="'SUPPLIER_CATEGORY_MAPPING.TITLE_CONFIG.ORIGINAL_CATEGORIES_TITLE' | translate"
  [originalCategoriesTooltip]="'SUPPLIER_CATEGORY_MAPPING.TITLE_CONFIG.ORIGINAL_CATEGORIES_TOOLTIP' | translate"
  [customCategoriesTitle]="'SUPPLIER_CATEGORY_MAPPING.TITLE_CONFIG.STORE_COLLECTIONS_TITLE' | translate"
  [customCategoriesTooltip]="'SUPPLIER_CATEGORY_MAPPING.TITLE_CONFIG.STORE_COLLECTIONS_TOOLTIP' | translate"
></app-wizard-category-mapping>

<ng-template #emptyState>
  <mat-card class="w-100-p">
    <div class="w-100-p py-100">
      <app-single-alert-with-icon
        icon="info"
        titleKey="SUPPLIER_CATEGORY_MAPPING.EMPTY_STATE.TITLE"
        descriptionKey="SUPPLIER_CATEGORY_MAPPING.EMPTY_STATE.DESCRIPTION"
        [button]="{
          type: 'callback',
          textKey: 'SUPPLIER_CATEGORY_MAPPING.EMPTY_STATE.ACTION',
          buttonClass: 'syncee-blue-button',
          callback: callbackFunction
        }"
      >
      </app-single-alert-with-icon>
    </div>
  </mat-card>
</ng-template>

import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { TranslateModule } from '@ngx-translate/core';

import { Intercom } from 'ng-intercom';

import { SupportTeamModule } from '../support-team/support-team.module';

import type { SupportTeamMember } from '../support-team/support-team-member';

@Component({
  selector: 'app-help-box',
  templateUrl: './help-box.component.html',
  styleUrls: ['./help-box.component.scss'],
  standalone: true,
  imports: [MatIconModule, TranslateModule, SupportTeamModule],
})
export class HelpBoxComponent {
  public readonly items: SupportTeamMember[] = [
    {
      image: 'images/support-team/support-team-member-six.webp',
    },
    {
      image: 'images/support-team/support-team-member-three.webp',
    },
    {
      image: 'images/support-team/support-team-member-five.webp',
    },
  ];

  constructor(public intercom: Intercom) {}
}

import { Inject, Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { DOCUMENT } from '@angular/common';
import { CachedUserService } from '../cached-user/cached-user.service';
import { UserService } from '../user/user.service';
import { PlatformCardData } from '../../main/users/platform-list/platform-card/platform-card.component';
import { RolesEnum } from '../../vo/roles/roles';

@Injectable({
  providedIn: 'root',
})
export class InstallService {
  constructor(
    @Inject(DOCUMENT) private document: any,
    private cachedUserService: CachedUserService,
    private userService: UserService
  ) {}

  public redirectToPlatform(platform: PlatformCardData, role?: RolesEnum): void {
    switch (platform.type) {
      case 'shopify': {
        this.installShopify(role);
        break;
      }
      case 'wix': {
        this.installWix(role);
        break;
      }
      case 'bigcommerce': {
        this.installBigcommerce(role);
        break;
      }
      case 'ecwid': {
        this.installEcwid(role);
        break;
      }
      case 'kmoshops': {
        this.installKmoshops();
        break;
      }
      case 'unas': {
        this.installUnas();
        break;
      }
      case 'ekm': {
        this.installEkm();
        break;
      }
      case 'zyro': {
        this.installZyro();
        break;
      }
      case 'squarespace': {
        this.installSquarespace(role);
        break;
      }
    }
  }

  installEcwid(role?: RolesEnum): void {
    const url = 'https://my.ecwid.com/cp/#apps:view=app&name=';
    const roleType = role || this.userService.getActualRole();
    this.document.location.href = url + (+roleType === 1 ? 'syncee-suppliers' : 'syncee');
  }

  installKmoshops(): void {
    this.document.location.href = 'https://my.kmoshops.be/cp/#app:name=pakket';
  }

  installBigcommerce(role?: RolesEnum): void {
    const url = 'https://login.bigcommerce.com/deep-links/marketplace/apps/';
    const roleType = role || this.userService.getActualRole();
    this.document.location.href = url + (+roleType === 1 ? '23617' : '19571');
  }

  installWix(role?: RolesEnum): void {
    const url = 'https://www.wix.com/app-market/';
    const roleType = role || this.userService.getActualRole();
    this.document.location.href = url + (+roleType === 1 ? 'syncee-for-suppliers' : 'syncee-global-dropshipping');
  }

  installUnas(): void {
    const callbackUrl = `${environment.installerURL}/api/unas_supplier_auth/authUser`;
    this.document.location.href = callbackUrl + '?user_id=' + this.cachedUserService.getCachedUser().id;
  }

  installEkm(): void {
    this.document.location.href = `https://api.ekm.net/connect/authorize?client_id=0bbfaa41-f9cc-4d04-b119-7ad651a21740&scope=openid profile tempest.customers.read tempest.orders.read tempest.orders.write tempest.products.read tempest.products.write tempest.categories.read tempest.settings.orderstatuses.read tempest.settings.domains.read tempest.settings.accountdetails.read tempest.settings.tax.read tempest.settings.currency.read offline_access&redirect_uri=https://installer.syncee.co/ekm_auth/callback&prompt=login&response_type=code`;
  }

  installShopify(role?: RolesEnum): void {
    const url = 'https://apps.shopify.com/';
    const roleType = role || this.userService.getActualRole();
    this.document.location.href = url + (+roleType === 1 ? 'syncee-for-suppliers' : 'syncee-1');
  }

  installZyro(): void {
    this.document.location.href = 'https://help.syncee.co/en/articles/6248927-how-to-install-syncee-to-your-zyro-store';
  }

  installSquarespace(role?: RolesEnum): void {
    const url = 'https://installer.syncee.co/';
    const roleType = role || this.userService.getActualRole();
    this.document.location.href = url + (+roleType === 1 ? 'squarespace_supplier_auth' : 'squarespace_auth');
  }
}

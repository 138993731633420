import { Pipe, PipeTransform } from '@angular/core';
import { getPlatformByType } from '../../utils/platforms/platform-config';
import { EcomTypeEnum } from '../../vo/enums/ecom-type-enum';
import { DATAFEED_INTEGRATIONS } from '../../utils/platforms/platform-item';

@Pipe({
  name: 'getPlatformName',
  standalone: true,
})
export class GetPlatformNamePipe implements PipeTransform {
  constructor() {}
  transform(ecomType: string | EcomTypeEnum): string {
    return ecomType && getPlatformByType(ecomType as EcomTypeEnum)?.name;
  }
}
